import React from 'react';
import { Link } from 'react-router-dom';

import './ButtonFixedStyles.scss';

const ButtonFixed = ({name}) => {
  const isLogged = !!localStorage.getItem('logged');

  return (
    <>
      {isLogged ? (
        <div className="flex justify-center items-center">
          <Link
            to="/resgatar"
            target='_blank'
            className="btn mx-auto fixed z-50 flex justify-center items-center"
          >
            {name}
          </Link >
        </div >
      ) : (
        <div />
      )}
    </>
  );
};

export default ButtonFixed;
