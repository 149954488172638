export default function VideoMail() {
  return(
    <>
    <div className="h-screen w-screen bg-white flex justify-center items-center">
      <div>
        <iframe src="https://player.vimeo.com/video/923022739?h=65f16dd27a&autoplay=0&loop=1&title=0&byline=0&portrait=0" className="max-w-[100%]" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        <a href="https://posvendadevantagens.com.br" className="button button-red mx-auto block max-w-max flex items-center px-[30px] rounded-full mt-[30px]">ACESSAR SITE</a>
      </div>
    </div>
    </>
  )
}