import { React, useContext, useEffect, useState } from "react";
import { GetUsers, SetPoints } from '../../../../../Backend/PanelManager';
import { LoadingContext } from "../../../../../Shared/LoadingContext";
// import { useNavigate } from 'react-router-dom';
import './Table.scss';
import Button from "../../../../../Components/Button";
import { useForm } from "react-hook-form";

const TablePoints = (props) => {
    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm();

    // const [results, setResults] = useState([]);
    const { setLoading } = useContext(LoadingContext);
    // const navigate = useNavigate();

    // const fetchResults = async () => {
    //     try {
    //         const response = await GetResults();
    //         setResults(response.data.results);
    //         if (results) {
    //             setLoading(false);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         setLoading(false);
    //         navigate('/');
    //     }
    // };

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedMonthLabel, setSelectedMonthLabel] = useState('');
    const [tableData, setTableData] = useState([]);
    const [monthPoints, setMonthPoints] = useState(0);
    const [monthData, setMonthData] = useState([]);
    const [allowDistribution, setAllowDistribution] = useState(false);
    const [distributedValue, setDistributedValue] = useState(0);
    const [balance, setBalance] = useState(0);



    const janeiro = [
        { id: 1, nome: 'Anakin Skywalker', cargo: 'Vendedor', valor: 100 },
        { id: 2, nome: 'Luke Skywalker', cargo: 'Balconista', valor: 200 },
        { id: 2, nome: 'Darth Vader', cargo: 'Gerente', valor: 200 },
    ];

    const fevereiro = [
        { id: 1, nome: 'Han Solo', cargo: 'Balconista', valor: 500 },
        { id: 2, nome: 'Mando', cargo: 'Gerente', valor: 100 },
        { id: 2, nome: 'Grogu', cargo: 'Vendedor', valor: 220 },
    ];

    const handleMonthChange = (event) => {
        const selectedPeriod = event.target.value;
        setSelectedMonth(selectedPeriod);

        if (selectedPeriod !== '') {

            const selectedPeriodLabel = event.target.options[event.target.selectedIndex].text;
            setSelectedMonthLabel(selectedPeriodLabel);

            const GetUsersData = async (selectedPeriod) => {

                setLoading(true);

                const data = {};
                data['month'] = selectedPeriod;

                const response = await GetUsers(data);

                setMonthPoints(response.data.points);
                setBalance(response.data.points);
                setTableData(response.data.users);

                setLoading(false);
            };

            GetUsersData(selectedPeriod);
        } else {
            setMonthPoints(0);
            setSelectedMonthLabel('');
            setTableData([]);
        }
    };

    const handleForm = () => {

        const formData = new FormData();
        formData.append('month', selectedMonth);

        let points = 0;
        monthData.forEach(function (item, index) {
            if(parseInt(item.value) > 0) {
                formData.append('user_uuid[]', item.datatarget);
                formData.append('user_points[]', parseInt(item.value));
                points += parseInt(item.value);
            }
        });

        if (points === monthPoints) {
            const SetPointsData = async (formData) => {

                setLoading(true);

                const response = await SetPoints(formData);

                if (response.success) {
                    props.setSelectedMonthLabel(selectedMonthLabel);
                    props.setMonthPoints(monthPoints);
                    props.setSuccess(true);
                }

                if (!response.success) {

                    for (const key in response.data) {
                        setLoading(false);

                        if (response.data.hasOwnProperty(key)) {
                            const error = response.data[key][0];
                            setError(key, { type: 'manual', message: error });
                        }
                    }
                }

                setLoading(false);
            };

            SetPointsData(formData);
        } else {
            setError('validate', { type: 'manual', message: 'O valor de distribuição dever ser igual a ' + monthPoints });
        }
    }

    const handleChange = event => {

        clearErrors();

        let value = event.target.value;

        const lastChar = value.substr(value.length - 1);
        if(!/^[0-9]*$/.test(lastChar)) {
            let newValue = value.slice(0, -1);
            event.target.value = newValue;
        }

        const datatarget = event.target.attributes.getNamedItem('datatarget').value;

        monthData.forEach(function (item, index) {
            if (item.datatarget === datatarget) {
                monthData.splice(index, 1);
            }
        })

        if (value !== '') {
            const data = {
                value,
                datatarget
            };
            monthData.push(data);
        }

        let points = 0;
        monthData.forEach(function (item, index) {
            points += parseInt(item.value);
        });

        if(points > monthPoints) {
            let newValue = value.slice(0, -1);
            event.target.value = newValue;

            value = event.target.value;

            monthData.forEach(function (item, index) {
                if (item.datatarget === datatarget) {
                    monthData.splice(index, 1);
                }
            })

            if (value !== '') {
                const data = {
                    value,
                    datatarget
                };
                monthData.push(data);
            }
            
            points = 0;
            monthData.forEach(function (item, index) {
                points += parseInt(item.value);
            });

            const newBalance = (monthPoints - points) + parseInt(value);

            setError('validate', { type: 'manual', message: 'Nesse campo você pode colocar no máximo ' + newBalance + ' pontos' });
        }

        setAllowDistribution(false);
        if (points === monthPoints) {
            setAllowDistribution(true);
        }

        setBalance(monthPoints - ((points > 0) ? points : 0) );

        setMonthData(monthData);
    };

    useEffect(() => {

        if(props?.monthsUndistributed?.length > 0) {
            const selectedPeriod = props?.monthsUndistributed[0].period;
            setSelectedMonth(selectedPeriod);
    
            if (selectedPeriod !== '') {
    
                const selectedPeriodLabel = props?.monthsUndistributed[0].month;
                setSelectedMonthLabel(selectedPeriodLabel);
    
                const GetUsersData = async (selectedPeriod) => {
    
                    setLoading(true);
    
                    const data = {};
                    data['month'] = selectedPeriod;
    
                    const response = await GetUsers(data);
    
                    setMonthPoints(response.data.points);
                    setBalance(response.data.points);
                    setTableData(response.data.users);
    
                    setLoading(false);
                };
    
                GetUsersData(selectedPeriod);
            } else {
                setMonthPoints(0);
                setSelectedMonthLabel('');
                setTableData([]);
            }
        }

    }, []);

    return (
        <>
            <div className='bg-white rounded-[20px] lg:w-[919px] mx-auto w-[316px] shadow-md pt-5 lg:pb-10 pb-5 lg:mt-12 -mt-6'>
                <div className="flex flex-col items-center justify-center">

                    <div className="inputs-container lg:w-[446px] w-[286px]">
                        <label className="pl-8">
                            Selecione o mês:
                        </label>
                        <select
                            className="input-field first-option-placeholder rounded-xl md:h-10 xl:h-14"
                            id="selectMonth"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                        >
                            {props?.monthsUndistributed?.map((item) => (
                                <option key={item.period} value={item.period}>
                                    {item.month}
                                </option>
                            ))}
                        </select>
                    </div>

                    {tableData.length > 0 ?
                        <>
                            <div className='flex justify-center items-center bg-[#CC0000] text-center lg:w-[689px] w-[231px] h-[50px] rounded-t-[15px] border-[1px] border-[#000000] lg:mt-8 mt-6'>
                                <h1 className="text-white lg:text-[24px] text-[22px] uppercase">PONTUAÇÃO {selectedMonthLabel}: {monthPoints}</h1>
                            </div>

                            <div className="points">
                                {tableData.map((item) => (
                                    <div
                                        className="lg:w-[689px] flex flex-col lg:flex-row justify-center items-center lg:mb-0 mb-2"
                                        key={item.uuid}
                                    >
                                        <div
                                            role="item"
                                            className="flex justify-center items-center lg:w-[233px] w-[231px] h-[50px] border-[1px] border-[#000000] lg:rounded-t-[0] rounded-t-[15px] lg:border-t-0 border-t-1 lg:border-r-0 border-r-1"
                                        >
                                            <h4 className="text-[22px]">{item.name}</h4>
                                        </div>
                                        <div className="flex justify-center items-center lg:w-[233px] w-[231px] h-[50px] border-[1px] border-[#000000] lg:bg-white bg-[#C0C8C8] lg:border-t-0 border-t-1 lg:border-r-0 border-r-1">
                                            <h4 className="text-[22px]">{item.user_role}</h4>
                                        </div>
                                        <div className="input flex justify-center items-center lg:w-[233px] w-[231px] h-[50px] border-[1px] border-[#000000] lg:border-t-0 border-t-1 lg:rounded-b-[0] rounded-b-[15px]">
                                            <input
                                                className="h-[31px] w-[195px] border-[1px] border-[#C9C9C9] rounded-[28px]"
                                                type="text"
                                                inputMode="numeric"
                                                min="0"
                                                placeholder="Informar pontos"
                                                datatarget={item.uuid}
                                                onChange={handleChange}
                                                name="user_points"
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {errors.validate && (
                                <div className="errors-message">
                                    <i className="error">{errors.validate.message}</i>
                                </div>
                            )}

                            <div className="info flex justify-center items-center flex-col lg:w-[590px] w-[286px] lg:h-[137px] h-[170px] bg-[#CC0000] rounded-[21px] mt-3 lg:mt-7">
                                <h1 className="font-bold text-white lg:text-[28px] text-[20px] text-center lg:mb-0 mb-1">ATENÇÃO</h1>
                                <ul className="leading-tight">
                                    <li className="text-start text-white lg:text-[26px] text-[20px] lg:mb-0 mb-4">
                                        Para prosseguir, é necessário<br className="lg:hidden" /> distribuir o valor total do mês.
                                    </li>
                                    <li className="text-start text-white lg:text-[26px] text-[20px]">
                                        Após a distribuição de pontos,<br className="lg:hidden" /> não será possível realizar alterações.
                                    </li>
                                </ul>
                            </div>

                            {!allowDistribution &&
                                <div className="flex justify-center items-center rounded-[13px] border-[#000000] border-[1px] lg:w-[385px] w-[286px] h-[51px] mt-8">
                                    <h1 className="lg:text-[26px] text-[20px]">FALTAM <strong className="text-[#CC0000]">{(balance >= 0 ? balance : balance)} PONTOS</strong> PARA DISTRIBUIR</h1>
                                </div>
                            }

                            {allowDistribution &&
                                <Button
                                    type="submit"
                                    text="DISTRIBUIR PONTUAÇÃO"
                                    variant="button-red w-[286px] my-8"
                                    onClick={handleForm}
                                />
                            }

                        </>
                        : ''}

                </div>

                {monthPoints > 0 && tableData.length === 0 &&
                <div className="flex flex-col justify-center items-center mx-auto lg:rounded-[13px] rounded-[15px] border-[#000000] border-[1px] lg:w-[404px] w-[286px] lg:h-[153px] h-[134px] mt-8 lg:mb-8 mb-3">
                    <h2 className="lg:text-[26px] text-[20px] text-[#000000] leading-[30px] text-center" style={{ fontFamily: 'var(--bebas-light)' }}>Não existem Vendedores (Balcão de peças) e<br />Consultores de Serviços cadastrados nesta concessionária.<br />Solicite o cadastro agora mesmo.</h2>
                </div>
                }


            </div>
        </>
    )
}

export default TablePoints;