import Client from './Client';
import {formatResponse} from "./formatResponse";

export async function Release() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/release');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}
