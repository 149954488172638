import { useContext, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import RecoverPasswordSuccess from "../RecoverPasswordSuccess";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import { ForgotPassword } from "../../../../Backend/forgotPassword";
import { LoadingContext } from "../../../../Shared/LoadingContext";
import { validateCPF } from "../../../Utils/validateCPF";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "../../../../Components/Button";
import InputText from "../../../../Components/InputText";
import { Grid } from "@mui/material";
import Init from "../../../Utils/Initializer";

const RecoveryPassword = ({ setViewLogin }) => {
  Init('area-aberta', 'esqueceu-sua-senha');
  const [success, setSuccess] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  const { setLoading } = useContext(LoadingContext);

  const recaptchaRef = useRef(null);

  const makeForgotPasswordRequest = async (cpf, recaptchaToken) => {
    const response = await ForgotPassword(cpf, recaptchaToken);
    if (response.success) {
      setSuccess(true);
      setLoading(false);
    } else {
      setError("cpf", {
        type: "manual",
        message: response.data?.cpf[0]
          ? response.data?.cpf[0]
          : "Ocorreu um erro, tente novamente",
      });
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const cpfIsValid = validateCPF(data.cpf);

    if (!cpfIsValid) {
      setError("cpf", { type: "manual", message: "CPF inválido" });
    } else {
      setLoading(true);
      recaptchaRef.current.executeAsync().then((recaptchaToken) => {
        recaptchaRef.current.reset();
        makeForgotPasswordRequest(data.cpf, recaptchaToken);
      });
    }
  };

  const handleReturnToLogin = () => {
    setViewLogin("login");
  };

  return (
    <>
      {success && <RecoverPasswordSuccess setViewLogin={setViewLogin} />}
      {!success && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login rounded-xl mt-7 lg:mt-0 mb-[100px] lg:mb-0">
            <h1 className="text-center mt-5">
              <strong>Esqueceu</strong> sua senha?
            </h1>
            <h2 className="text-center mb-3 mt-5">
              <strong>Informe seu CPF</strong> para criar uma nova.
            </h2>

            <Grid container spacing={1}>
              <Grid item md={12} xs={12}>
                <Controller
                  name="cpf"
                  control={control}
                  render={(field) => {
                    return (
                      <>
                        <InputText
                          onChange={field.field.onChange}
                          value={field.value}
                          label="CPF"
                          placeholder="Digite aqui seu CPF"
                          errors={errors.cpf}
                          inputType="text"
                          mask="999.999.999-99"
                          inputMode="numeric"
                        />
                      </>
                    );
                  }}
                />
              </Grid>
            </Grid>
            <div
              style={{ marginBottom: "10px", marginTop: "35px", width: "100%" }}
            >
              <Button
                textLoading="Solicitando senha..."
                type="submit"
                variant={`button-red w-full mb-5`}
                text="SOLICITAR SENHA"
              />
            </div>
            <Button
              type="button"
              variant="button-gray w-full mb-1"
              text="VOLTAR AO LOGIN"
              onClick={handleReturnToLogin}
            />
          </div>

          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          />
        </form>
      )}
    </>
  );
};

export default RecoveryPassword;
