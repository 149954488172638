import React, { useContext, useEffect } from "react";
import { ShopUrl } from "../../../../Backend/Shop";
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from "../../../../Shared/LoadingContext";

const Shop = () => {
    const navigate = useNavigate();
    const { setLoading } = useContext(LoadingContext);
    let initialized = false
    useEffect(() => {
        setLoading(false);
        if (!initialized) {
            initialized = true;
            const shopUrl = async () => {
                const response = await ShopUrl();
                if (response.success && response.data.url) {
                    return response.data.url;
                }
                return null;
            }
            shopUrl().then((url) => {
                if (url === null) {
                    navigate('/como-participar');
                } else {
                    window.location.href = url;
                }
            }).catch(e => {
                navigate('/como-participar');
            });
        }
        setLoading(true);
    });

    return (
        <>
            <div className="flex justify-center items-center h-screen">
                <p className="text-[40px]">CARREGANDO...</p>
            </div>
        </>
    )
}

export default Shop;
