import { useEffect } from "react";
import TitleAndDescription from "./Metadata";
import PageView from "./Gtm";

const Init = (accessType, subSection, title = null, description = null) => {
  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;

      TitleAndDescription(title, description);
      PageView({
        'access_type': accessType,
        'environment': 'honda-sites',
        'macro_section': 'honda-de-vantagens',
        'section': 'campanha-pro-honda-de-vantagens',
        'sub_section': subSection,
        'segment': 'multisegmentos'
      });
    }
  }, []);
};

export const InitWithoutEffect = (accessType, subSection, title = null, description = null) => {
  TitleAndDescription(title, description);
  PageView({
    'access_type': accessType,
    'environment': 'honda-sites',
    'macro_section': 'honda-de-vantagens',
    'section': 'campanha-pro-honda-de-vantagens',
    'sub_section': subSection,
    'segment': 'multisegmentos'
  });
};

export default Init;
