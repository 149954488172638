import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {Hit} from "../../../../Backend/QrCode";
import Init from "../../../Utils/Initializer";

const QtCode = () => {
  Init('area-aberta', 'qr-code');
  const params = useParams();
  const navigate = useNavigate();
  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      hit(params.id);
    }
  }, []);

  const hit = async (code) => {
    try {
      const response = await Hit('incentive', code);
      if(response.success) {
        localStorage.setItem('source', 'qrcode');
        localStorage.setItem('medium', code);
      }
      setTimeout(() => {
        navigate('/');
      }, 300);
    } catch (error) {
      setTimeout(() => {
        navigate('/');
      }, 300);
    }
  };

  return (
    <div>
      <p>carregando...</p>
    </div>
  );
}

export default QtCode;
