import { createContext, useEffect, useState } from 'react';
import { Release } from "../Backend/Release";

export const OverlayContext = createContext();

export const OverlayProvider = ({ children }) => {
  const [overlayState, setOverlayState] = useState(true);

  const setOverlay = (value) => {
    setOverlayState(value);
  };

  useEffect(() => {
    const request = async () => {
      const response = await Release();
      let overlay = true;
      if (response.success) {
        setOverlay(!response.data.status);
        overlay = !response.data.status;
        return {
          overlay: !response.data.status,
        };
      } else {
        return {
          overlay: true,
        };
      }
    }
    request().then(r => {
      if (r.overlay) {
        setOverlay(true);
        window.location.href = "https://www.honda.com.br";
      } else {
        setOverlayState(false);
      }
    }).catch(() => {
      setOverlayState(true);
      window.location.href = "https://www.honda.com.br";
    });
  }, []);

  return (
    <OverlayContext.Provider value={{ setOverlay, overlayState }}>
      {children}
    </OverlayContext.Provider>
  );
};
