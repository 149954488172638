import Client from './Client';
import { formatResponse } from './formatResponse';

import qs from 'qs';

export async function SignIn(login, password, recaptchaToken) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post(
        '/incentive/login',
        qs.stringify({
          login,
          password,
          'g-recaptcha-response': recaptchaToken,
        })
      );

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function Profile() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/profile');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function Logout() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/logout');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}
