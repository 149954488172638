import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Logout } from '../../Backend/auth';

import './MenuStyle.scss';

import Selo from '../../Resources/Media/Common/selo.webp';
import Honda from '../../Resources/Media/Common/logo-white.webp';
import MenuMobile from '../../Resources/Media/Incentive/Icons/menu-hamburguer.svg';
import CloseMenuMobile from '../../Resources/Media/Incentive/Icons/close-mobile.svg';
import CloseMenu from '../../Resources/Media/Incentive/Icons/close.webp';
import LogOut from '../../Resources/Media/Incentive/Icons/logout.png';
import { GetSaldo } from '../../Backend/MyResults';
import { LoadingContext } from '../../Shared/LoadingContext';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const touchStartX = useRef(null);
  const touchEndX = useRef(null);
  const isLogged = localStorage.getItem('logged');
  const userRole = localStorage.getItem('user_role');
  const { setLoading } = useContext(LoadingContext);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleTouchStart = (event) => {
    touchStartX.current = event.touches[0].clientX;
  };

  const handleTouchMove = (event) => {
    touchEndX.current = event.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current && touchEndX.current) {
      const touchDiff = touchEndX.current - touchStartX.current;
      if (touchDiff > 50) {
        setIsOpen(false);
      } else if (touchDiff < -50) {
        setIsOpen(true);
      }
    }
    touchStartX.current = null;
    touchEndX.current = null;
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const navigate = useNavigate();

  const name = localStorage.getItem('name');

  const formattedName = name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase();

  const handleLogout = async () => {
    await Logout();
    navigate('/');
    localStorage.removeItem('sex');
    localStorage.removeItem('name');
    localStorage.removeItem('logged');
    localStorage.removeItem('user_role');
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const location = useLocation();

  const scrollIntoHome = (elementId) => {
    const componentToScroll = document.getElementById(elementId);
    window.scrollBy({
      top: componentToScroll.getBoundingClientRect().top - 110,
      behavior: 'smooth',
      scrollMode: 'always',
    });
  };

  const { hash } = location;

  useEffect(() => {
    const handleScroll = () => {
      if (hash === '#award') {
        scrollIntoHome('award');
      } else if (hash === '#participate') {
        scrollIntoHome('participate');
      } else if (hash === '') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    const delay = 500;
    const timerId = setTimeout(() => {
      handleScroll();
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, [hash]);

  useEffect(() => {
    if (!isLogged) {
      navigate('/');
    }
  }, [isLogged]);

  useEffect(() => {

    if (location.pathname === "/painel-gestor-de-pontos") {
      if(userRole !== '10') {
        navigate('/como-participar');
      }
    }

    if (location.pathname === "/resultados") {
      if(userRole === '10') {
        navigate('/como-participar');
      }
    }

  });

  const saldoValue = localStorage.getItem('saldo');

  useEffect(() => {
    const getSaldoNumber = async () => {
      const response = await GetSaldo();
      localStorage.setItem('saldo', response.data.points);
    };
    getSaldoNumber();
  }, []);

  return (
    <header className="header">
      <div className="header-wrapper">
        <div className="header-logo">
          <Link to="/como-participar" onClick={() => {
            if (location.pathname !== "/como-participar") {
              setLoading(true);
            }
          }}>

            <img
              className="lg:w-[184px] w-[122px]"
              src={Selo}
              alt="Pro Honda de Vantagens"
            />
          </Link>
        </div>

        <div className="flex lg:block items-center justify-center bg-[#CC0000] lg:w-[176px] w-[101px] lg:h-[79px] h-[30px] rounded-[10px] lg:px-4 px-1 lg:pt-1">
          <Link
            to="/editar-perfil"
            className='leading-[1]'
          >
            <span
              className='text-[18px] text-white leading-[0.9]'
              style={{ fontFamily: 'var(--bebas-light)' }}
            >
              Olá, <strong>{formattedName}.</strong>
            </span>
          </Link>
          {typeof saldoValue !== "undefined" && saldoValue !== "0" && saldoValue !== "-" ? (
            <>
              <span className="text-[28px] text-[#F6C103] hidden lg:block leading-[0.9]">
                {saldoValue}
                {' '}<small className='text-[16px] text-white'>ponto{saldoValue > 1 ? "s" : ""}.</small>
              </span>
            </>
          ) : null}
          <div className={`${saldoValue !== "undefined" && saldoValue !== "0" && saldoValue !== "-" ? 'flex leading-[1.4]' : 'block leading-[1.6]'} justify-between items-center`}>
            <a
              href="/editar-perfil"
              className="underline text-[#F6C103] text-[16px] hidden lg:block"
            >
              Meu Perfil
            </a>
            <button className="hidden lg:block -mt-[0.15rem]">
              <img
                width={37}
                src={LogOut}
                alt="Logout"
                onClick={handleLogout}
              />
            </button>
          </div>
        </div>

        <div
          className={`header-menu ${isOpen ? 'open' : ''}`}
          ref={menuRef}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            className={`header-menu-toggle ${isOpen ? 'open' : ''}`}
            onClick={toggleMenu}
          >
            <img
              src={isOpen ? CloseMenuMobile : MenuMobile}
              className="lg:hidden"
              alt="Menu"
            />
          </div>

          <div className={`header-menu-items ${isOpen ? 'open' : ''}`}>
            <div className='mr-10'>
              <aside className="header-menu-items-selos block max-w-max m-auto lg:hidden mt-[6rem]">
                <picture className="mb-[3.2rem] block">
                  <img
                    width={180}
                    src={Honda}
                    alt="Pro Honda de Vantagens"
                  />
                </picture>
              </aside>

              <div className="lg:hidden">
                <div className="bg-[#CC0000] border-[1px] border-white w-[176px] h-[79px] rounded-[10px] mx-auto px-4 pt-1">
                  <span
                    className='text-[18px] text-white leading-[0.9]'
                    style={{ fontFamily: 'var(--bebas-light)' }}
                  >
                    Olá, <strong>{formattedName}.</strong>
                  </span>

                  {typeof saldoValue !== "undefined" && saldoValue !== "undefined" && saldoValue !== "0" && saldoValue !== "-" ? (
                    <>
                      <span className="text-[28px] text-[#F6C103] block leading-[0.9]">
                        {saldoValue}
                        {' '}<small className='text-[16px] text-white'>ponto{saldoValue > 1 ? "s" : ""}.</small>
                      </span>
                    </>
                  ) : null}

                  <div className={`${saldoValue !== "undefined" && saldoValue !== "0" && saldoValue !== "-" ? 'flex leading-[1.4]' : 'block leading-[1.6]'} justify-between items-center`}>
                    <a
                      href="/editar-perfil"
                      className="underline text-[#F6C103] text-[16px]"
                    >
                      Meu Perfil
                    </a>
                    <button className='-mt-[0.15rem]'>
                      <img
                        width={37}
                        src={LogOut}
                        alt="Logout"
                        onClick={handleLogout}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <nav>
              <button
                className="close-menu hidden lg:block bg-transparent"
                onClick={toggleMenu}
              >
                <img
                  className="ml-1 mt-4"
                  width={48}
                  alt="Fechar menu"
                  src={CloseMenu}
                />
              </button>
              <ul className='lg:mt-12 mt-10'>
                <li className='lg:block hidden'>
                  <Link to="/editar-perfil" onClick={() => {
                    toggleMenu();
                    if (location.pathname !== "/editar-perfil") {
                      setLoading(true);
                    }
                  }}>
                    MEU PERFIL
                  </Link>
                </li>

                {userRole === '10' &&
                <li>
                  <Link to="/painel-gestor-de-pontos" onClick={() => {
                    toggleMenu();
                    if (location.pathname !== "/painel-gestor-de-pontos") {
                      setLoading(true);
                    }
                  }}>
                    PAINEL DE PONTOS
                  </Link>
                </li>
                }

                <li>
                  <Link to="/como-participar" onClick={() => {
                    toggleMenu();
                    if (location.pathname !== "/como-participar") {
                      setLoading(true);
                    }
                  }}>
                    COMO PARTICIPAR
                  </Link>
                </li>

                <li>
                  <Link to="/premios" onClick={() => {
                    toggleMenu();
                    if (location.pathname !== "/premios") {
                      setLoading(true);
                    }
                  }}>
                    PREMIAÇÃO
                  </Link>
                </li>

                {userRole !== '10' &&
                  <li>
                    <Link to="/resultados" onClick={() => {
                      toggleMenu();
                      if (location.pathname !== "/resultados") {
                        setLoading(true);
                      }
                    }}>
                      MEUS RESULTADOS
                    </Link>
                  </li>
                }

                {userRole !== '10' &&
                <li>
                  <Link to="/resgatar" target='_blank' onClick={toggleMenu}>
                    RESGATAR PRÊMIOS
                  </Link>
                </li>
                }

                <li>
                  <Link to="/contato" onClick={() => {
                    toggleMenu();
                    if (location.pathname !== "/contato") {
                      setLoading(true);
                    }
                  }}>
                    FALE CONOSCO
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Regulamento_Pro_Honda_De_Vantagens.pdf`} onClick={toggleMenu}>
                    REGULAMENTO
                  </Link>
                </li>
                <li>
                  <Link to={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Privacidade_Pro_Honda_De_Vantagens.pdf`} onClick={toggleMenu}>
                    PRIVACIDADE
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
