import { Routes, Route } from 'react-router-dom';
import { useContext } from 'react';
import { LoadingContext } from './Shared/LoadingContext';
import { OverlayContext } from "./Shared/OverlayContext";
import LoadingIcons from 'react-loading-icons';

import NotFound from './Resources/Views/Incentive/NotFound';
import Home from './Resources/Views/Incentive/Home';
import LoginForm from './Resources/Views/Incentive/Login';
import CreatePassword from './Resources/Views/Incentive/CreatePassword';
import FormRegister from './Resources/Views/Incentive/Register';
import RegisterSuccess from './Resources/Views/Incentive/RegisterSuccess';
import EditProfile from './Resources/Views/Incentive/EditProfile';
import MyResults from './Resources/Views/Incentive/MyResults';
import Contact from './Resources/Views/Incentive/Contact';
import Award from './Resources/Views/Incentive/Award';
import Shop from './Resources/Views/Incentive/Shop';
import EditProfileSuccess from './Resources/Views/Incentive/EditProfileSuccess';
import QrCode from "./Resources/Views/Incentive/QrCode";
import PanelManager from './Resources/Views/Incentive/PanelManager';
import VideoEmail from './Resources/Views/Incentive/Videos/01';
import VideoEmail2 from './Resources/Views/Incentive/Videos/02';

function App() {
  const { loadingState } = useContext(LoadingContext);
  const { overlayState } = useContext(OverlayContext);
  return (
    <>
      {overlayState && (
        <div className="overlay">
          <div className="ico">
            <LoadingIcons.SpinningCircles />
          </div>
        </div>
      )}
      {loadingState && (
        <div className="loading">
          <div className="ico">
            <LoadingIcons.SpinningCircles />
          </div>
        </div>
      )}
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/cadastre-se" element={<FormRegister />} />
        <Route path="/sucesso" element={<RegisterSuccess />} />
        <Route path="/criar-senha" element={<CreatePassword />} />
        <Route path="/como-participar" element={<Home />} />
        <Route path="/editar-perfil" element={<EditProfile />} />
        <Route path="/editar-perfil-sucesso" element={<EditProfileSuccess />} />
        <Route path="/resultados" element={<MyResults />} />
        <Route path="/contato" element={<Contact />} />
        <Route path="/premios" element={<Award />} />
        <Route path="/resgatar" element={<Shop />} />
        <Route path="/qrc/:id" element={<QrCode/>}></Route>
        <Route path="/painel-gestor-de-pontos" element={<PanelManager/>}></Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/video-distribuicao-de-pontos-pro-honda-de-vantagens" element={<VideoEmail/>}></Route>
        <Route path="/video-resgate-de-premios" element={<VideoEmail2/>}></Route>
      </Routes>
    </>
  );
}

export default App;
