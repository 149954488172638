import './FooterStyles.scss';
import { Link } from 'react-router-dom';
import honda from '../../Resources/Media/Common/logo.webp';
import proHonda from '../../Resources/Media/Common/prohonda.webp';

const Footer = () => {
  const isLogged = !!localStorage.getItem('logged');

  return (
    <footer className={`footer ${isLogged ? 'lg:pb-16 pb-10' : ''}`}>
      <section className={`footer-links flex lg:w-[800px] lg:flex-row flex-col ${isLogged ? 'justify-between' : 'justify-center'} items-center lg:gap-16 gap-5`}>
        <div className='lg:contents flex items-center justify-center gap-16'>
          <div className={isLogged ? 'order-1' : 'order-0'}>
            <picture>
              <img
                src={honda}
                width={142}
                alt="Honda"
              />
            </picture>
          </div>
          <div className='lg:order-3 order-2'>
            <picture>
              <img
                src={proHonda}
                width={94}
                alt="Pro Honda"
                className='mx-0'
              />
            </picture>
          </div>
        </div>
        {isLogged ? (
          <div className="flex flex-col lg:flex-row justify-center items-center lg:order-2 order-3 lg:gap-12 gap-6 lg:mb-0 mb-5">
            <Link to="/contato">FALE CONOSCO</Link>
            <Link
              to={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Regulamento_Pro_Honda_De_Vantagens.pdf`}
            >
              REGULAMENTO
            </Link>
            <Link
              to={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Privacidade_Pro_Honda_De_Vantagens.pdf`}
            >
              PRIVACIDADE
            </Link>
          </div>
        ) : (
          <div />
        )}
      </section>
      <span>
        <a
          href="https://agenciasonora.com.br/?utm_source=pro+honda+de+vantagens&utm_id=honda"
          target="_blank"
          rel="noreferrer"
        >
          Powered by SONORA
        </a>
      </span>
    </footer>
  );
};

export default Footer;
