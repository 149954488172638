import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';

import '../Login/LoginStyles.scss';
import Button from '../../../../Components/Button';
import LoginTemplate from '../../Templates/LoginTemplate';
import InputText from '../../../../Components/InputText';
import CreatePasswordSuccess from '../CreatePasswordSuccess';
import { useLocation } from 'react-router-dom';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import { ForgotPasswordSave } from '../../../../Backend/forgotPassword';
import { Grid } from '@mui/material';
import TemplateBackground from '../../../../Components/TemplateBackground';
import { ValidateToken } from '../../../../Backend/validateToken';
import Init from '../../../Utils/Initializer';

const CreatePassword = ({ setViewLogin }) => {
  Init('area-aberta', 'esqueceu-senha:criar-nova-senha');
  const [tokenIsValid, setTokenIsValid] = useState(true);
  const [success, setSuccess] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  const { setLoading } = useContext(LoadingContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await ForgotPasswordSave(
      data.password,
      data.password_confirmation,
      token
    );

    if (response.success) {
      setSuccess(true);
      setLoading(false);
    } else {
      setError('password_confirmation', {
        type: 'manual',
        message: response.data.cpf[0],
      });
      setLoading(false);
    }
  };

  const validateToken = async () => {
    setLoading(true);

    const response = await ValidateToken(token);
    if (response.success) {
      setTokenIsValid(true);
    } else {
      setTokenIsValid(false);
    }

    setLoading(false);
  };

  useEffect(() => {
    validateToken();
  }, []);

  return (
    <>
      <LoginTemplate>
        <TemplateBackground />
        <div>
          {success && <CreatePasswordSuccess />}
          {!success && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='login rounded-xl mt-7 lg:mt-0 mb-[100px] lg:mb-0'>
                <h1 className="mt-1">
                  Crie sua{" "}<strong>nova senha</strong>
                </h1>
                {tokenIsValid === false && (
                  <p className="text-black text-center">CHAVE DE RECUPERAÇÃO INVÁLIDA</p>
                )}
                {tokenIsValid === true && (
                  <>
                    <div className='mt-10'>
                      <Grid container spacing={1}>
                        <Grid item md={12} xs={12}>
                          <Controller
                            name="password"
                            control={control}
                            render={(field) => {
                              return (
                                <>
                                  <InputText
                                    onChange={field.field.onChange}
                                    value={field.value}
                                    label="NOVA SENHA"
                                    placeholder="Digite aqui sua nova senha"
                                    errors={errors.password}
                                    inputPassword
                                    inputType="text"
                                  />
                                </>
                              );
                            }}
                          />
                        </Grid>
                        <Grid item md={12} xs={12} style={{ marginTop: '5px' }}>
                          <Controller
                            name="password_confirmation"
                            control={control}
                            render={(field) => {
                              return (
                                <>
                                  <InputText
                                    onChange={field.field.onChange}
                                    value={field.value}
                                    label="CONFIRMAR NOVA SENHA"
                                    placeholder="Confirme aqui sua nova senha"
                                    errors={errors.password_confirmation}
                                    inputPassword
                                    inputType="text"
                                  />
                                </>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                    <Button
                      textLoading="Solicitando senha..."
                      type="submit"
                      variant={`button-red w-full mt-8`}
                      text="PROSSEGUIR"
                    />
                  </>
                )}
              </div>
            </form>
          )}
        </div>
      </LoginTemplate >
    </>
  );
};

export default CreatePassword;
