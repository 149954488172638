import Client from './Client';
import { formatResponse } from './formatResponse';

export async function Hit(guard, code) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get(guard + '/qrcodes/' + code);
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}
