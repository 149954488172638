import './BackgroundPagesStyles.scss';

const BackgroundPages = (props) => {
    return (
        <div className={`background-pages ${props.variation}`}>
            {props.children}
        </div>
    );
};

export default BackgroundPages;