import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../Components/Button";
import TemplateBackground from "../../../../Components/TemplateBackground";
import icon from '../../../Media/Incentive/Icons/success.svg';
import Background from "../../../../Components/Background";
import { Logout } from "../../../../Backend/auth";

const PanelManagerSuccess = (props) => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    const handleRefresh = () => {
        window.location.reload();
    };

    const navigate = useNavigate();

    const handleLogout = async () => {
        await Logout();
        navigate('/');
        localStorage.removeItem('sex');
        localStorage.removeItem('name');
        localStorage.removeItem('logged');
        localStorage.removeItem('user_role');
    };

    return (
        <>
            <Background>
                <TemplateBackground />
                <div className='login rounded-xl mt-[140px] lg:mt-[150px] lg:mb-[70px]'>
                    <div className="mb-5 w-full">
                        <picture>
                            <img
                                className="block mx-auto mt-2"
                                src={icon}
                            />
                        </picture>
                    </div>
                    <div>
                        <h1 className="!mb-3">
                            <strong>Pontuação creditada</strong><br />
                            com sucesso!
                        </h1>
                        <p className="leading-none text-center my-4" style={{ fontFamily: 'var(--bebas-middle)' }}>
                            <b>Obrigado</b> por <b>incentivar</b> sua equipe<br />
                            com {props.monthPoints} pontos no mês de {props.selectedMonthLabel}.
                        </p>
                        <p className="leading-none text-center mb-4" style={{ fontFamily: 'var(--bebas-middle)' }}>
                            Contamos com você para<br />
                            proporcionar mais vantagens!
                        </p>
                    </div>
                    <div>
                        <Button
                            onClick={handleRefresh}
                            variant="button-red w-72 mt-1 mb-4 mx-auto"
                            text="VOLTAR AO PAINEL"
                        />

                        <Button
                            onClick={handleLogout}
                            variant="button-gray w-72 mx-auto"
                            text="SAIR"
                        />
                    </div>
                </div>
            </Background>
        </>
    )
};

export default PanelManagerSuccess;