import { useContext } from 'react';
import { LoadingContext } from '../../../../../Shared/LoadingContext';
import { EditProfile } from '../../../../../Backend/editProfile';
import { UserContext } from '../../../../../Shared/UserContext';
import { useNavigate } from 'react-router-dom';

const useHandlersEditProfile = ({
  setValue,
  recaptchaRef,
  setError,
  setScrollToErrorUpdate,
  scrollToErrorUpdate,
  setSuccess,
  storesSelected,
  watch
}) => {
  const { setLoading } = useContext(LoadingContext);
  const { setUserState } = useContext(UserContext);
  const navigate = useNavigate();

  const handleCustomSubmit = (data) => {
    setLoading(true);
    recaptchaRef.current.executeAsync().then((recaptchaToken) => {
      setLoading(true);
      recaptchaRef.current.reset();
      onSubmit(data, recaptchaToken);
    });
  };

  const onSubmit = async (data, recaptchaToken) => {
    const response = await EditProfile({
      cpf: data.cpf,
      name: data.name,
      mobile: data.mobile,
      birth_at: data.birth_at,
      sex: data.sex,
      city: data.city,
      state: data.state,
      role: data.role,
      companies:
        storesSelected.length > 0
          ? storesSelected.map((item) => item.value)
          : undefined,
      email: data.email,
      email_confirmation: data.email_confirmation,
      password: data.password,
      password_confirmation: data.password_confirmation,
      optin_rules: data.optin_rules,
      optin_privacy: data.optin_privacy,
      recaptchaToken: recaptchaToken,
    });

    if (response.success) {
      setUserState({ ...response.data.user, logged: true, res_group: true });
      setLoading(false);
      setSuccess(true);
      navigate('/editar-perfil-sucesso');
    } else {
      for (const key in response.data) {
        setLoading(false);

        if (response.data.hasOwnProperty(key)) {
          const error = response.data[key][0];
          setError(key, { type: 'manual', message: error });
          setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        }
      }
      setLoading(false);
    }
  };

  return {
    handleCustomSubmit,
  };
};

export default useHandlersEditProfile;
