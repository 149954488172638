import Client from './Client';
import { formatResponse } from './formatResponse';

export async function SearchStore(search, companies, state) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/store/search', {
        search,
        companies,
        state,
      });
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}
