/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import DefaultTemplate from '../../Templates/DefaultTemplate';
import './MyResultsStyles.scss';
import TotalCards from './TotalCards';
import { GetResults } from '../../../../Backend/MyResults';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import TableWinner from './TableWinners';
import BackgroundPages from '../../../../Components/BackgroundPages';
import WaitPage from './Wait';
import Init from "../../../Utils/Initializer";

const MyResults = () => {
  Init('area-logada', 'meus-resultados');
  const [detailIndex, setDetailIndex] = useState(0);
  const [results, setResults] = useState([]);
  const [points, setPoints] = useState(0);
  const { setLoading } = useContext(LoadingContext);
  const [classcard, setClassCard] = useState(detailIndex);
  const navigate = useNavigate();
  const [isResult, setIsResult] = useState(false);
  const [balance, setBalance] = useState(0);

  const fetchResults = async () => {
    try {
      const response = await GetResults();
      setPoints(response.data.points);
      setResults(response.data.results);

      if (response.data.results.length > 0) {
        setIsResult(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      navigate('/');
    }
  };

  const handleSetDetailIndex = (id) => {
    setDetailIndex((prevIndex) => (prevIndex === id ? 0 : id));
    setClassCard(id);
  };

  useEffect(() => {
    setClassCard(detailIndex);
  }, [detailIndex]);

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    fetchResults();
  }, []);

  useEffect(() => {
    const saldoValue = localStorage.getItem('saldo');
    if (typeof saldoValue !== "undefined" && saldoValue !== "undefined" && saldoValue !== "-") {
      setBalance(saldoValue);
    }
  }, []);

  return (
    <DefaultTemplate>
      {isResult ? (
        <>
          <BackgroundPages variation="background-result">
            <div className="my-results">
              <h1 className="text-[#CC0000] lg:text-[40px] text-[32px] mt-20">
                MEUS RESULTADOS
              </h1>
              <section>
                <>
                  <div className="flex flex-col lg:gap-[3.5rem] gap-0 lg:mt-12 mt-6 lg:flex-row items-center justify-center">
                    <TotalCards
                      value={points || 0}
                      hasButton
                      showButton
                      detailComponent={<TableWinner />}
                      setDetailIndex={setDetailIndex}
                      id={1}
                      openDetail={detailIndex === 1}
                      descriptionValue={
                        <>
                          <span className='text-[#0A0C14] text-[24px] leading-none mb-3' style={{ fontFamily: 'var(--bebas-bold)' }}>
                            TOTAL DE<br />
                            PONTOS CONQUISTADOS
                          </span>
                        </>
                      }
                      results={results}
                      onClick={() => handleSetDetailIndex(0)}
                      classcard={classcard}
                    />

                    <div className='lg:hidden'>
                      {detailIndex === 1 && <TableWinner results={results} />}
                    </div>

                    <TotalCards
                      value={balance}
                      //value={saldoValue || 0}
                      setDetailIndex={setDetailIndex}
                      ShowLink="Acessar Prêmios"
                      descriptionValue={
                        <>
                          <span className='text-[#0A0C14] text-[24px] leading-none mb-3' style={{ fontFamily: 'var(--bebas-bold)' }}>
                            PONTO{balance > 1 ? 'S' : ''} DISPONÍV
                            {balance > 1 ? 'EIS' : 'EL'}
                            <br />
                            PARA RESGATE
                          </span>
                        </>
                      }
                      classcard={classcard}
                      classMargin
                    />

                  </div>
                </>
                <>
                  <div className='hidden lg:flex'>
                    {detailIndex === 1 && <TableWinner results={results} />}
                  </div>
                </>

              </section>
            </div>
          </BackgroundPages>
        </>
      ) : (
        <>
          <BackgroundPages variation="background-full">
            <WaitPage />
          </BackgroundPages>
        </>
      )
      }

    </DefaultTemplate>
  );
};

export default MyResults;
