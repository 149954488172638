const TitleAndDescription = function(title = null, description = null) {
  if(title === null) {
    title = 'Pro Honda de Vantagens';
  }

  if(description === null) {
    description = 'Acesse agora e acumule mais conquistas com o Kit Lubrificante Pro Honda!';
  }

  document.querySelector('meta[name="title"]').setAttribute('content', title);
  document.querySelector('meta[property="og:title"]').setAttribute('content', title);
  document.querySelector('meta[name="twitter:title"]').setAttribute('content', title);

  document.querySelector('meta[name="description"]').setAttribute('content', description);
  document.querySelector('meta[property="og:description"]').setAttribute('content', description);
  document.querySelector('meta[name="twitter:description"]').setAttribute('content', description);
}

export default TitleAndDescription;
