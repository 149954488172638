import Background from '../../../../Components/Background';
import Footer from '../../../../Components/Footer';

const LoginTemplate = (props) => {
    return (
        <>
            <Background removeBackground={props.removeBackground}>
                {props.children}
            </Background>
            <Footer />
        </>
    );
};

export default LoginTemplate;