import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';

import TemplateBackground from '../../../../Components/TemplateBackground';
import Button from '../../../../Components/Button';
import '../Login/LoginStyles.scss';

import icon from '../../../Media/Incentive/Icons/user.svg';
import DefaultTemplate from '../../Templates/DefaultTemplate';
import { UserContext } from '../../../../Shared/UserContext';
import Background from '../../../../Components/Background';
import Init from "../../../Utils/Initializer";

const RegisterSuccess = () => {
  Init('area-logada', 'bem-vindo');
  const { userState } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <>
      <DefaultTemplate>
        <Background>
          <TemplateBackground />
          <div className='login rounded-xl mt-[140px] lg:mt-[95px] lg:mb-0'>
            <div className="mb-4 w-full">
              <picture>
                <img
                  className="block mx-auto mt-2"
                  src={icon}
                />
              </picture>
            </div>
            <div>
              <h1 className="!mb-3">
                <strong>Bem-vind{userState?.sex === "F" ? "a" : "o"},</strong>
                <br />
                {typeof userState?.firstName !== "undefined" ? userState?.firstName : ""}.
              </h1>
              <div className='text-center'>
                <p style={{ fontFamily: 'var(--bebas-bold)' }}>Seu cadastro foi realizado com sucesso!</p>
                <p className="leading-none my-3">
                  Aproveite e confira a mecânica<br />
                  completa, seu desempenho, prêmios,<br />
                  lojas associadas e muito mais.
                </p>
              </div>
            </div>
            <div>
              <Button
                onClick={() =>
                  navigate('/como-participar')
                }
                variant="button-red w-72 mt-5 mb-4 mx-auto"
                text="COMO PARTICIPAR"
              />

              <Button
                onClick={() => navigate('/premios')}
                variant="button-gray w-72 mb-2 mx-auto"
                text="PREMIAÇÃO"
              />
            </div>
          </div>
        </Background>
      </DefaultTemplate>
    </>
  );
};

export default RegisterSuccess;
