import { useEffect } from 'react';
import selo from '../../Resources/Media/Common/selo.webp';

import AOS from 'aos';
import 'aos/dist/aos.css';

const TemplateBackground = () => {
    const isLogged = !!localStorage.getItem('logged');

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div>
                <section>
                    {isLogged ? (
                        <>
                            <div
                                className='lg:block hidden'
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                            >
                                <img
                                    className='block lg:w-[428px] w-[193px]'
                                    src={selo}
                                    alt="Pro Honda de vantagens"
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                data-aos="zoom-in"
                                data-aos-duration="1500"
                            >
                                <img
                                    className='block lg:w-[428px] w-[193px]'
                                    src={selo}
                                    alt="Pro Honda de vantagens"
                                />
                            </div>
                        </>
                    )}
                </section>
            </div>
        </>
    )
}

export default TemplateBackground;