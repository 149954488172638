const PageView = function(pageInfo) {
  window.dataLayer?.push({
    event: 'page_view',
    page: {
      location:
        document.location.origin +
        document.location.pathname +
        document.location.search,
      title: document.title,
    },
    pageInfo: pageInfo
  });
}

export const ButtonClick = function(component, title, text) {
  window.dataLayer?.push({
    event: 'button_click',
    component: component,
    title: title,
    text: text
  });
}

export const LinkClick = function(component, title, text) {
  window.dataLayer?.push({
    event: 'link_click',
    component: component,
    title: title,
    text: text
  });
}

export default PageView;
