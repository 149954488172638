export function formatResponse(response) {
  if (response.status === 200) {
    return { success: true, code: 200, data: response.data };
  } else {
    return {
      success: false,
      code: response?.status,
      data: response?.response?.data?.errors,
    };
  }
}
