/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../Components/Button';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import './RegisterStyles.scss';
import InputCheckbox from '../../../../Components/InputCheckbox';
import { useEffect } from 'react';
import useHandlersRegister from './hooks/useHandlersRegister';
import { statesBrazilianList } from '../../../../Shared/statesBrazilianList';
import { optionsSexList } from '../../../../Shared/optionsSexList';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import RightCornerElement from '../../../Media/Incentive/Icons/triangle.png';

import TrashPink from '../../../Media/Incentive/Icons/trash.svg';

import AOS from 'aos';
import 'aos/dist/aos.css';
import LoginTemplate from '../../Templates/LoginTemplate';
import InputText from '../../../../Components/InputText';
import ReCAPTCHA from 'react-google-recaptcha';
import InputSelect from '../../../../Components/InputSelect';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import { SearchStore } from '../../../../Backend/searchStore';
import { Register, Profile, RegisterEnabled } from '../../../../Backend/User';
import { GetRoles } from '../../../../Backend/Roles';
import { LoadingContext } from "../../../../Shared/LoadingContext";
import Init from "../../../Utils/Initializer";
import { validateBirthOnBlur } from "../../../Utils/validateBirth";

const FormRegister = () => {
  Init('area-aberta', 'cadastre-se');
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);
  const [valueStore, setValueStore] = useState(undefined);
  const [storesSelected, setStoresSelected] = useState([]);
  const [disabledCompaniesField, setDisableCompaniesField] = useState(false);
  const [roles, setRoles] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const recaptchaRef = useRef(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    watch,
    setValue,
    clearErrors,
    register,
  } = useForm({ resolver: yupResolver(schema), defaultValues: { optin_rules: false } });

  useEffect(() => {
    const logged = async () => {
      const response = await Profile();
      return response.success;
    }

    // const registerEnabled = async () => {
    //   const response = await RegisterEnabled();
    //   return response.data.enabled || false;
    // }

    // registerEnabled().then((response) => {
    //   if (!response) {
    //     navigate('/');
    //   }
    // });

    // logged().then((response) => {
    //   if (response) {
    //     navigate('/editar-perfil');
    //   }
    // });
  }, []);

  const { handleCustomSubmit } = useHandlersRegister({
    setValue,
    setError,
    recaptchaRef,
    scrollToErrorUpdate,
    setScrollToErrorUpdate,
    setSuccess,
    storesSelected,
  });

  const customStylesAsyncSelect = {
    container: (provided) => {
      return {
        ...provided,
        width: '100%',
      };
    },
    input: (provided) => {
      return {
        ...provided,
        cursor: 'text',
        fontWeight: '300',
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        height: '55px',
        backgroundColor: 'white',
        border: `1px solid ${errors.companies ? 'var(--color-red-error)' : 'var(--color-gray-100)'
          }`,
        borderRadius: '28px',
        padding: '0 20px',
        fontSize: '18px',
        fontWeight: '300',
        fontFamily: 'var(--bebas-light)',
        lineHeight: '1.1',
        color: errors.companies
          ? 'var(--color-red-error)'
          : 'var(--color-gray-200)',
        '&:placeholder': {
          color: 'var(--color-gray-100)',
          fontWeight: '300',
        },
      };
    },
    option: (provided, state) => ({
      ...provided,
      '&:disabled': { color: '#CED4DA', backgroundColor: 'transparent' },
      padding: 10,
      backgroundColor: 'white',
      color: 'var(--color-blue-100)',
      boderRadius: '12px',
      '&:hover': { backgroundColor: 'var(--color-red)', color: 'white' },
      border: 'solid 1px rgba(0, 0, 0, 0.1)',
    }),
    dropdownIndicator: () => ({
      color: 'transparent',
    }),
  };

  const handleSelectOption = (selected) => {
    setValueStore(selected);
  };

  const handleAddStore = () => {
    if (valueStore) {
      // subistuti item
      setStoresSelected([]);
      // 
      setStoresSelected((prev) => [...prev, valueStore]);
      setValue('companies', valueStore.value);
      setValueStore(null);
      clearErrors('companies');
    }
  };

  const handleRemoveStoreSelected = (value) => {
    const newArr = storesSelected.filter((item) => item.value !== value);
    setStoresSelected(newArr);
  };

  const searchStoreOptions = async (inputValues) => {
    const companies = storesSelected.map((store) => store.value);
    const uf = watch('state');
    const state = typeof uf !== 'undefined' ? uf : '';
    if (inputValues.length > 3) {
      const responseStores = await SearchStore(inputValues, companies, state);
      if (responseStores.success) {
        return responseStores.data;
      }
    }
  };

  const phone = watch('mobile');
  const checkIfPhoneIsCompleted = () => {
    if (phone.length > 1 && phone.length < 15) {
      setError('mobile', {
        type: 'manual',
        message: 'Número de telefone incompleto.',
      });
    }

    if (phone?.length === 1) {
      clearErrors('mobile');
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await GetRoles();
      setRoles(response?.data);

      if (roles) {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      navigate('/');
    }
  };

  const cargos = roles.map((role) => {
    return {
      ...role,
      value: role.uuid,
    };
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: false });
    }
  }, [errors, scrollToErrorUpdate]);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <>
      <LoginTemplate removeBackground>
        <div className="register">
          <div className="container mx-auto">
            <form onSubmit={handleSubmit(handleCustomSubmit)}>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              />

              <div className="register__white-container-top">
                <div className="title text-center lg:py-3 pt-0">
                  <h1 className="leading-none lg:w-full w-[256px]">
                    <strong>CADASTRE-SE</strong> E APROVEITE AS <strong>INCRÍVEIS VANTAGENS!</strong>
                  </h1>
                </div>

                <Grid container spacing={2}>
                  <Grid item md={12} xs={12}>
                    <Controller
                      name="cpf"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputText
                              onChange={onChange}
                              value={value}
                              label="CPF"
                              placeholder="Digite aqui seu CPF"
                              errors={errors.cpf}
                              mask="999.999.999-99"
                              inputType="text"
                              inputMode="numeric"
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputText
                              onChange={onChange}
                              value={value}
                              label="NOME COMPLETO"
                              placeholder="Digite aqui seu nome completo"
                              errors={errors.name}
                              inputType="text"
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      control={control}
                      name="mobile"
                      render={({ field: { onChange, value } }) => {
                        return (
                          <InputText
                            label="CELULAR"
                            value={value}
                            placeholder="Digite aqui seu celular"
                            styleLabel="!text-[#031E78]"
                            onChange={(e) => {
                              onChange(e.target.value);
                              clearErrors('mobile');
                            }}
                            mask="(99) 99999-9999"
                            inputMode="numeric"
                            errors={errors?.mobile}
                            onBlur={() => {
                              checkIfPhoneIsCompleted({
                                inputMobile: value,
                                setError,
                                clearErrors,
                              });
                            }}
                          />
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="birth_at"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputText
                              onChange={onChange}
                              value={value}
                              label="DATA DE NASCIMENTO"
                              placeholder="Digite aqui sua data de nascimento"
                              errors={errors.birth_at}
                              inputType="text"
                              mask="99/99/9999"
                              inputMode="numeric"
                              onBlur={() =>
                                validateBirthOnBlur({
                                  setError,
                                  inputBirth: watch('birth_at'),
                                  clearErrors,
                                })
                              }
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="sex"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputSelect
                              onChange={onChange}
                              value={value}
                              label="GÊNERO"
                              errors={errors.sex}
                              inputType="select"
                              optionsSelect={optionsSexList}
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <Controller
                      name="state"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputSelect
                              onChange={onChange}
                              value={value}
                              label="UF"
                              errors={errors.state}
                              optionsSelect={statesBrazilianList}
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputSelect
                              onChange={onChange}
                              value={value}
                              label="CARGO"
                              errors={errors.role}
                              inputType="select"
                              optionsSelect={cargos}
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  {disabledCompaniesField ? undefined : (
                    <>
                      <label
                        className="lg:w-full w-[184px] leading-none"
                        style={{
                          margin: '12px 0px -3px 36px',
                          fontSize: '22px',
                          color: errors.companies
                            ? 'var(--color-red-error)'
                            : 'var(--color-black)',
                        }}
                      >
                        NOME FANTASIA E/OU CÓDIGO DA EMPRESA
                      </label>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          md={9}
                          xs={12}
                          display="flex"
                          flexDirection="column"
                        >
                          <Controller
                            name="companies"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <div className="flex flex-col w-full">
                                  <AsyncSelect
                                    unstyled={true}
                                    // isDisabled={disableStore}
                                    loadOptions={searchStoreOptions}
                                    styles={customStylesAsyncSelect}
                                    isMulti={false}
                                    error={true}
                                    value={valueStore}
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.label}
                                    onChange={handleSelectOption}
                                    placeholder="Digite aqui o nome fantasia ou código da concessionária"
                                    noOptionsMessage={() => ''}
                                    loadingMessage={() => ''}
                                  />
                                </div>
                              );
                            }}
                          />
                          {errors.companies && (
                            <div className="errors-message">
                              <img src={RightCornerElement} alt="" />
                              <p className="error">
                                {errors.companies.message}
                              </p>
                            </div>
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={3}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          <Button
                            type="button"
                            variant={(valueStore ? "button-red" : "button-gray") + " w-full mt-2 lg:mt-0 lg:ml-4 mx-auto"}
                            style={{
                              marginBottom: errors?.companies ? '31px' : '0',
                            }}
                            text={storesSelected.length > 0 ? "SUBSTITUIR" : "ADICIONAR"}
                            onClick={handleAddStore}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {storesSelected.length > 0 && (
                    <>
                      <label
                        className="lg:text-[22px] text-[14px]"
                        style={{ margin: '12px 0px -3px 36px' }}
                      >
                        CONCESSIONÁRIA CADASTRADA
                      </label>

                      <Grid
                        item
                        xs={12}
                        sx={{ paddingTop: '0px !important' }}
                      >
                        <div className="register__stores-list flex">
                          {storesSelected.map((item, index) => (
                            <div className="register__stores-list__item">
                              <p style={{ fontFamily: 'var(--bebas-bold)' }}>{item.label}</p>
                              <img
                                src={TrashPink}
                                alt=""
                                onClick={() =>
                                  handleRemoveStoreSelected(item.value)
                                }
                              />
                            </div>
                          ))}
                        </div>
                      </Grid>
                    </>
                  )}

                  <Grid item md={12} xs={12}>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputText
                              onChange={onChange}
                              value={value}
                              label="E-MAIL"
                              placeholder="Digite aqui seu e-mail"
                              errors={errors.email}
                              inputType="text"
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Controller
                      name="email_confirmation"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputText
                              onChange={onChange}
                              value={value}
                              label="CONFIRMAR E-MAIL"
                              placeholder="Confirme seu e-mail"
                              errors={errors.email_confirmation}
                              inputType="text"
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputText
                              onChange={onChange}
                              value={value}
                              label="SENHA"
                              inputPassword
                              placeholder="Digite aqui sua senha"
                              errors={errors.password}
                              inputType="text"
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item md={12} xs={12}>
                    <Controller
                      name="password_confirmation"
                      control={control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <>
                            <InputText
                              onChange={onChange}
                              value={value}
                              inputPassword
                              label="CONFIRMAR SENHA"
                              placeholder="Confirme aqui sua nova senha"
                              errors={errors.password_confirmation}
                              inputType="text"
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>

                  <div item className="pl-2 pt-3">
                    <InputCheckbox
                      control={control}
                      name="optin_rules"
                      id="cad_confirmo"
                      errors={errors.optin_rules}
                      label={
                        <p
                          style={{
                            fontSize: '14px',
                            fontFamily: 'var(--bebas-light)'
                          }}
                        >
                          Li, aceito o{' '}
                          <a
                            className="underline"
                            target="_blank"
                            rel="noreferrer"
                            href={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Regulamento_Pro_Honda_De_Vantagens.pdf`}
                          >
                            regulamento
                          </a>{' '}
                          e receber informações
                          <br className="lg:hidden" /> da{' '}
                          Campanha Pro Honda de Vantagens.
                        </p>
                      }
                    />
                  </div>

                  <div item className="pl-2 lg:pt-0 pt-4">
                    <InputCheckbox
                      control={control}
                      name="optin_privacy"
                      id="cad_confirmo2"
                      errors={errors.optin_privacy}
                      label={
                        <p style={{
                          fontSize: '14px',
                          fontFamily: 'var(--bebas-light)'
                        }}>
                          Li e aceito os <a
                            href={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Privacidade_Pro_Honda_De_Vantagens.pdf`}
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                          >Termos de Política de Privacidade</a>
                          <br className="lg:hidden" /> da{' '}

                          Campanha Pro Honda de Vantagens
                          {' '}
                          e da{' '}
                          <a
                            href="https://www.honda.com.br/politica-de-privacidade"
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                          >
                            Honda
                          </a>.
                        </p>
                      }
                    />
                  </div>
                </Grid>

                <Button
                  type="submit"
                  variant="button-red w-72 lg:mb-2 mb-4 mx-auto"
                  text="CADASTRAR DADOS"
                />
              </div>
            </form>
          </div>
        </div>
      </LoginTemplate>
    </>
  );
};

export default FormRegister;
