import Client from './Client';
import { formatResponse } from './formatResponse';

export async function EditProfile({
  cpf,
  name,
  mobile,
  birth_at,
  sex,
  state,
  email,
  role,
  companies,
  email_confirmation,
  password,
  password_confirmation,
  optin_rules,
  optin_privacy,
  recaptchaToken,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.put('/incentive/profile', {
        cpf,
        name,
        mobile,
        birth_at,
        sex,
        state,
        role,
        companies,
        email,
        email_confirmation,
        password,
        password_confirmation,
        optin_rules,
        optin_privacy,
        'g-recaptcha-response': recaptchaToken,
      });

      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}
