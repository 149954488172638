import { Link } from "react-router-dom";

const WaitPage = () => {
    return (
        <>
            <div className='flex flex-col justify-center items-center bg-white rounded-[20px] shadow-md lg:w-[904px] w-[320px] lg:h-[213px] h-[374px] mx-auto text-center relative z-10'>
                <h1 className='text-[#CC0000] text-[28px]' style={{ fontFamily: 'var(--bebas-bold)' }}>MEUS RESULTADOS</h1>
                <p className='text-[#000000] text-[20px] leading-tight lg:mt-2 mt-4' style={{ fontFamily: 'var(--bebas-bold)' }}>
                    Aguarde a apuração para{' '}<br className="lg:hidden" />
                    ver os seus resultados!
                </p>
                <div className="rounded-[20px] border-[1px] border-black h-[33px] w-[221px] mx-auto mt-5 lg:mt-6">
                    <Link
                        to={`${process.env.REACT_APP_BACKEND_HOST}/incentive/Regulamento_Pro_Honda_De_Vantagens.pdf`}
                        className="text-[20px]"
                        style={{ fontFamily: 'var(--bebas-bold)' }}
                    >
                        CONSULTE O <span className="underline">REGULAMENTO</span>
                    </Link>
                </div>
            </div >
        </>
    )
}

export default WaitPage;