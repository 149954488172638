import './InputCheckboxStyles.scss';
import RightCornerElement from '../../Resources/Media/Incentive/Icons/triangle.png';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@mui/material';
import {
  NoChecked,
  Checked,
} from '../../Resources/Media/Incentive/Icons/CheckIcons';

const InputCheckbox = (props) => {
  return (
    <div className={`${props.errors ? 'error' : ''} container-input-checkbox`}>
      <div>
        <Controller
          name={props.name}
          control={props.control}
          defaultValue={false}
          render={({ field }) => (
            <Checkbox
              onChange={field.onChange}
              value={field.value}
              icon={<NoChecked error={props.errors} />}
              checkedIcon={<Checked />}
            />
          )}
        />
        <label className="description-label" htmlFor={props.id}>
          {props.label}
        </label>
      </div>
      {props.errors && (
        <div className="errors-message">
          <img src={RightCornerElement} alt="" />
          <i className="error"> {props.errors.message}</i>
        </div>
      )}
    </div>
  );
};

export default InputCheckbox;
