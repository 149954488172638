// TotalCards.js

import React, { useState } from 'react';
import './TotalCardsStyles.scss';
import { Link } from 'react-router-dom';

const TotalCards = ({
  value,
  color,
  descriptionValue,
  firstCard,
  hasButton,
  detailComponent,
  openDetail,
  setDetailIndex,
  id,
  classcard,
  showButton,
  showLink,
  classMargin,
}) => {
  const handleClick = () => {
    if (hasButton) {
      openDetail ? setDetailIndex(0) : setDetailIndex(id);
    }
  };

  return (
    <div
      className={`lg:flex lg:flex-col ${openDetail && classcard === id
        ? 'active'
        : classcard !== 0 && classcard !== id
          ? 'inactive'
          : ''
        }`}
    >
      <div className={`total-card-results flex flex-col items-center justify-center text-center ${classMargin ? 'lg:mt-0 mt-8' : ''}`}>
        <div
          className={`bg-white rounded-[30px] shadow-md w-[258px] h-[258px] flex flex-col items-center justify-start 
          ${color} ${showButton ? '' : 'hide-button'} ${firstCard ? 'first-card' : ''}`}
        >
          <h1 className='text-[#CC0000] text-[70px] mt-10'>
            {value}
          </h1>

          {descriptionValue}

          {showButton && (
            <div className="total-card-results__container-button text-[18px] flex items-center justify-center">
              <button onClick={handleClick}>
                {openDetail ? 'Ocultar' : 'Distribuir'}
              </button>
            </div>
          )}

          {showLink && (
            <>
              <div className='total-card-results__container-button text-[18px] flex items-center justify-center'>
                <button onClick={handleClick}>
                  {openDetail ? 'Ocultar' : 'Extrato'}
                </button>
              </div>
            </>
          )}
        </div>
        {openDetail && <div className="arrow-detail-results hidden lg:block" />}
      </div>
      <div className="lg:hidden">
        {openDetail && (
          <div className="flex flex-col items-center justify-center">
            <div className="arrow-detail-results" />
            {/* <div className="detail-card">
              {detailComponent}
            </div> */}
            {/* <div className="detail-card-desktop hidden lg:block">
              {detailComponent}
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalCards;
