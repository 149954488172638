import * as Yup from 'yup';

export const schema = Yup.object({
  name: Yup.string().required('Esta informação é necessária.'),
  birth_at: Yup.string().required('Esta informação é necessária.'),
  sex: Yup.string().required('Esta informação é necessária.'),
  state: Yup.string().required('Esta informação é necessária.'),
  mobile: Yup.string().required('Esta informação é necessária.'),
  email: Yup.string()
    .required('Esta informação é necessária.')
    .email('Insira um email válido.'),
  email_confirmation: Yup.string()
    .required('Esta informação é necessária.')
    .test('has_email_value', 'Esta informação é necessária.', function (value) {
      const emailValue = this.resolve(Yup.ref('email'));
      if (emailValue) {
        return !!value;
      }
      return true;
    })
    .test(
      'email_confirmation_is_equal',
      'Os e-mails não correspondem.',
      function (value) {
        const campo1 = this.resolve(Yup.ref('email'));
        if (campo1 !== value) {
          return false;
        }
        return true;
      }
    ),
  password: Yup.string(),
  password_confirmation: Yup.string()
    .test(
      'has_password_value',
      'Esta informação é necessária.',
      function (value) {
        const campo1 = this.resolve(Yup.ref('password'));
        const hasValue = !!value;
        if (campo1?.length > 0 && !hasValue) {
          return false;
        }
        return true;
      }
    )
    .test(
      'password_confirmation_is_equal',
      'As senhas não correspondem.',
      function (value) {
        const campo1 = this.resolve(Yup.ref('password'));
        if (campo1 !== value && campo1?.length > 0) {
          return false;
        }
        return true;
      }
    ),

  companies: Yup.array().of(Yup.string().required('Esta informação é necessária.')).min(1, 'Esta informação é necessária.'),
  role: Yup.string().required('Esta informação é necessária.'),
});
