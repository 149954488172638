import { useNavigate } from 'react-router-dom';
import Button from '../../../../Components/Button';
import createPasswordSuccessIcon from '../../../Media/Incentive/Icons/password.svg';
import '../Login/LoginStyles.scss';
import Init from "../../../Utils/Initializer";

const CreatePasswordSuccess = ({ setViewLogin }) => {
  Init('area-aberta', 'nova-senha-gerada-com-sucesso');
  const navigate = useNavigate();
  const handleReturnToLogin = () => {
    navigate('/');
  };
  return (
    <div className='login rounded-xl mt-7 lg:mt-0 mb-[100px] lg:mb-0'>
      <div>
        <picture>
          <img
            src={createPasswordSuccessIcon}
            className='mx-auto mt-1'
          />
        </picture>
        <h1 className="mt-6">
          <strong>Nova senha</strong>{' '}gerada<br />
          com{' '}<strong>sucesso!</strong>
        </h1>
      </div>
      <div>
        <p className='text-center leading-none my-8'>
          <b style={{ fontFamily: 'var(--bebas-bold)' }}>Utilize a senha criada</b> para<br />
          acumular conquistas com<br />
          Kit Lubrificante Pro Honda.
        </p>
      </div>
      <Button
        type="button"
        variant="button-gray w-full mt-4"
        text="VOLTAR AO LOGIN"
        onClick={handleReturnToLogin}
      />
    </div>
  );
};

export default CreatePasswordSuccess;
