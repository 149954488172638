import Client from './Client';

export async function GetResults() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/result');

      resolve({
        success: true,
        code: response.status,
        data: response.data,
      });
    } catch (error) {
      resolve({
        success: false,
        code: error.response.status,
        data: error.response.data || null,
      });
    }
  });
}

export async function GetSaldo() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/shop/balance');

      resolve({
        success: true,
        code: response.status,
        data: response.data,
      });
    } catch (error) {
      resolve({
        success: false,
        code: error.response.status,
        data: error.response.data || null,
      });
    }
  });
}
