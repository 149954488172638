import Client from './Client';
import {formatResponse} from "./formatResponse";

export async function GetPoints() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/manager-points');
      resolve(formatResponse(response));
    } catch (error) {
      resolve(formatResponse(error));
    }
  });
}

export async function SetCompany(company) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/manager-points/set/store/' + company);
        resolve(formatResponse(response));
      } catch (error) {
        resolve(formatResponse(error));
      }
  });
}

export async function GetUsers(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Client.post('/incentive/manager-points/users', payload);
        resolve(formatResponse(response));
      } catch (error) {
        resolve(formatResponse(error));
      }
    });
  }

  export async function GetDistributed(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Client.post('/incentive/manager-points/distributed', payload);
        resolve(formatResponse(response));
      } catch (error) {
        resolve(formatResponse(error));
      }
    });
  }

  export async function SetPoints(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Client.post('/incentive/manager-points', payload);
        resolve(formatResponse(response));
      } catch (error) {
        resolve(formatResponse(error));
      }
    });
  }
