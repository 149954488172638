/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../../../Components/Button';
import { Grid, Paper } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import ReCAPTCHA from 'react-google-recaptcha';
import AsyncSelect from 'react-select/async';

import './EditProfileStyles.scss';
import { useEffect } from 'react';
import useHandlersEditProfile from './hooks/useHandlersEditProfile';

import RightCornerElement from '../../../Media/Incentive/Icons/triangle.png';
import { statesBrazilianList } from '../../../../Shared/statesBrazilianList';
import { optionsSexList } from '../../../../Shared/optionsSexList';

import { useNavigate } from 'react-router-dom';
import { scrollToFirstError } from '../../../Utils/ScrollToErrors';
import { SearchStore } from '../../../../Backend/searchStore';

import DefaultTemplate from '../../Templates/DefaultTemplate';
import InputText from '../../../../Components/InputText';
import InputSelect from '../../../../Components/InputSelect';
import EditProfileSuccess from '../EditProfileSuccess';

import TrashPink from '../../../Media/Incentive/Icons/trash.svg';
import Client from '../../../../Backend/Client';
import { GetRoles } from '../../../../Backend/Roles';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import Background from '../../../../Components/Background';
import Init from '../../../Utils/Initializer';
import { validateBirthOnBlur } from '../../../Utils/validateBirth';

const EditProfile = () => {
  Init('area-logada', 'editar-perfil');
  const navigate = useNavigate();
  const [valueStore, setValueStore] = useState(undefined);
  const [storesSelected, setStoresSelected] = useState([]);
  const [success, setSuccess] = useState(false);
  const [scrollToErrorUpdate, setScrollToErrorUpdate] = useState(0);
  const [showCompaniesField, setShowCompaniesField] = useState(false);
  const [roles, setRoles] = useState([]);
  const [role_count, setRoleCount] = useState(null);
  const [user_role, setUserRole] = useState('');
  const { setLoading } = useContext(LoadingContext);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { optin_rules: false },
  });

  const recaptchaRef = useRef(null);

  const { handleCustomSubmit } = useHandlersEditProfile({
    setValue,
    setError,
    recaptchaRef,
    setScrollToErrorUpdate,
    scrollToErrorUpdate,
    setSuccess,
    storesSelected,
    watch,
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToFirstError({ hasHeader: true });
    }
  }, [errors, scrollToErrorUpdate]);

  const customStylesAsyncSelect = {
    container: (provided) => {
      return {
        ...provided,
        width: '100%',
      };
    },
    input: (provided) => {
      return {
        ...provided,
        cursor: 'text',
        fontWeight: '300',
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        height: '55px',
        backgroundColor: 'white',
        border: `1px solid ${errors.companies ? 'var(--color-red-error)' : 'var(--color-gray-100)'
          }`,
        borderRadius: '28px',
        padding: '0 20px',
        fontSize: '18px',
        fontWeight: '300',
        fontFamily: 'var(--bebas-light)',
        lineHeight: '1.1',
        color: errors.companies
          ? 'var(--color-red-error)'
          : 'var(--color-gray-200)',
        '&:placeholder': {
          color: 'var(--color-gray-100)',
          fontWeight: '300',
        },
      };
    },
    option: (provided, state) => ({
      ...provided,
      '&:disabled': { color: '#CED4DA', backgroundColor: 'transparent' },
      padding: 10,
      backgroundColor: 'white',
      color: 'var(--color-blue-100)',
      boderRadius: '12px',
      '&:hover': { backgroundColor: 'var(--color-red)', color: 'white' },
      border: 'solid 1px rgba(0, 0, 0, 0.1)',
    }),
    dropdownIndicator: () => ({
      color: 'transparent',
    }),
  };

  const handleSelectOption = (selected) => {
    setValueStore(selected);
  };

  const handleChangeRole = (target) => {
    let role = target.options[target.selectedIndex].text;
    setUserRole(role);

    if (role !== 'Gerente de Pós-venda' && storesSelected.length > 1) {
      setStoresSelected([]);
      setValue('companies', []);
    }

  };

  const handleAddStore = () => {
    if (valueStore) {

      if (user_role !== 'Gerente de Pós-venda') {
        // subistuti item
        setStoresSelected([]);
      }

      //
      setStoresSelected((prev) => [...prev, valueStore]);
      setValue('companies', [...watch('companies'), valueStore.value]);
      setValueStore(null);
      clearErrors('companies');
    }
  };

  const handleRemoveStoreSelected = (value) => {
    const newArr = storesSelected.filter((item) => item.value !== value);
    setStoresSelected(newArr);

    if (newArr.length === 0) {
      setValue('companies', []);
    }
  };

  const searchStoreOptions = async (inputValues) => {
    const companies = storesSelected.map((store) => store.value);
    const uf = watch('state');
    const state = typeof uf !== 'undefined' ? uf : '';
    if (inputValues.length > 3) {
      const responseStores = await SearchStore(inputValues, companies, state);
      if (responseStores.success) {
        return responseStores.data;
      }
    }
  };

  const phone = watch('mobile');
  const checkIfPhoneIsCompleted = () => {
    if (phone.length > 1 && phone.length < 15) {
      setError('mobile', {
        type: 'manual',
        message: 'Número de telefone incompleto.',
      });
    }

    if (phone?.length === 1) {
      clearErrors('mobile');
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await GetRoles();
      setRoles(response?.data);
      if (roles) {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      navigate('/');
    }
  };

  const cargos = roles.map((role) => {
    return {
      ...role,
      value: role.uuid,
    };
  });

  const isLogged = localStorage.getItem('logged');
  useEffect(() => {
    if (!isLogged) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const getResGroupValue = async () => {
      const res = await Client.get('/incentive/profile');
      if (res.status === 200) {
        setValue('name', res?.data?.user.name);
        setValue('sex', res?.data?.user.sex);
        setValue('birth_at', res?.data?.user.birth);
        setValue('state', res?.data?.user.state);
        setValue('mobile', res?.data?.user.mobile);
        setValue('email', res?.data?.user.email);
        setValue('email_confirmation', res?.data?.user.email);
        setValue('cpf', res?.data?.user.cpf);
        setValue('role', res?.data?.user.user_role_uuid);
        setValue('user_role', res?.data?.user.user_role);
        setUserRole(res?.data?.user.user_role);
        setRoleCount(res?.data?.user.role_count);

        const listStores = res?.data?.user?.companies?.map((item) => {
          return {
            label: `(${item.code}) ${item.company}`,
            value: item.uuid,
          };
        });

        setValue(
          'companies',
          res?.data?.user?.companies?.map((item) => {
            return item.uuid;
          })
        );

        setStoresSelected(listStores);

        setShowCompaniesField(!res?.data?.user.has_group);
      }
    };
    getResGroupValue();
  }, []);

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <>
      <DefaultTemplate>
        <Background removeBackground>
          <div className="register lg:mt-24 mt-16">
            <div className="container mx-auto">
              <form noValidate onSubmit={handleSubmit(handleCustomSubmit)}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                />

                <div className="register__white-container-top">
                  <div className="title text-center lg:py-3 pt-0">
                    <h1 className="leading-none">
                      <strong>MEU PERFIL</strong>
                    </h1>
                  </div>

                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <div>
                        <label className="px-5 ml-1 disabled">
                          CPF
                        </label>
                        <Controller
                          control={control}
                          name="cpf"
                          render={({ field: { onChange, value } }) => {
                            return (
                              <Paper
                                className="locked"
                                sx={{
                                  marginTop: '2px',
                                  width: '100%',
                                  height: '55px',
                                  borderRadius: '28px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  padding: '4px 20px',
                                  border: 'solid 1px #C9C9C9',
                                  boxShadow: 'none',
                                  opacity: 0.6,
                                }}
                                elevation={4}
                              >
                                <p
                                  style={{
                                    color: '#C9C9C9',
                                    fontSize: '18px',
                                    textAlign: 'start',
                                    fontFamily: 'var(--bebas-light)'
                                  }}
                                >
                                  {value?.replace(
                                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                    '$1.$2.$3-$4'
                                  )}
                                </p>
                              </Paper>
                            );
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputText
                                onChange={onChange}
                                value={value}
                                label="NOME COMPLETO"
                                placeholder="Digite aqui seu nome completo"
                                errors={errors.name}
                                inputType="text"
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        control={control}
                        name="mobile"
                        render={({ field: { onChange, value } }) => {
                          return (
                            <InputText
                              label="CELULAR"
                              value={value}
                              placeholder="Digite aqui seu celular"
                              styleLabel="!text-[#031E78]"
                              onChange={(e) => {
                                onChange(e.target.value);
                                clearErrors('mobile');
                              }}
                              mask="(99) 99999-9999"
                              inputMode="numeric"
                              errors={errors?.mobile}
                              onBlur={() => {
                                checkIfPhoneIsCompleted({
                                  inputMobile: value,
                                  setError,
                                  clearErrors,
                                });
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="birth_at"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputText
                                onChange={onChange}
                                value={value}
                                label="DATA DE NASCIMENTO"
                                placeholder="Digite aqui sua data de nascimento"
                                errors={errors.birth_at}
                                inputType="text"
                                mask="99/99/9999"
                                inputMode="numeric"
                                onBlur={() =>
                                  validateBirthOnBlur({
                                    setError,
                                    inputBirth: watch('birth_at'),
                                    clearErrors,
                                  })
                                }
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="sex"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputSelect
                                onChange={onChange}
                                value={value}
                                label="GÊNERO"
                                errors={errors.sex}
                                inputType="select"
                                optionsSelect={optionsSexList}
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Controller
                        name="state"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputSelect
                                onChange={onChange}
                                value={value}
                                label="UF"
                                errors={errors.state}
                                optionsSelect={statesBrazilianList}
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>

                    {role_count === 0 &&
                      <Grid item md={12} xs={12}>
                        <Controller
                          name="role"
                          control={control}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <>
                                <InputSelect
                                  onChange={(e) => {
                                    onChange(e.target.value);
                                    handleChangeRole(e.target);
                                  }}
                                  value={value}
                                  label="CARGO"
                                  errors={errors.role}
                                  inputType="select"
                                  optionsSelect={cargos}
                                />
                              </>
                            );
                          }}
                        />
                      </Grid>
                    }

                    {role_count > 0 &&
                      <Grid item md={12} xs={12}>
                        <div>
                          <label className="px-5 ml-1 disabled">
                            CARGO
                          </label>
                          <Controller
                            control={control}
                            name="user_role"
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Paper
                                  className="locked"
                                  sx={{
                                    marginTop: '2px',
                                    width: '100%',
                                    height: '55px',
                                    borderRadius: '28px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    padding: '4px 20px',
                                    border: 'solid 1px #C9C9C9',
                                    boxShadow: 'none',
                                    opacity: 0.6,
                                  }}
                                  elevation={4}
                                >
                                  <p
                                    style={{
                                      color: '#C9C9C9',
                                      fontSize: '18px',
                                      textAlign: 'start',
                                      fontFamily: 'var(--bebas-light)'
                                    }}
                                  >
                                    {value}
                                  </p>
                                </Paper>
                              );
                            }}
                          />
                        </div>
                      </Grid>
                    }


                    {showCompaniesField && (
                      <>
                        <label
                          className="lg:w-full w-[184px] leading-none"
                          style={{
                            margin: '12px 0px -3px 36px',
                            fontSize: '22px',
                            color: errors.companies
                              ? 'var(--color-red-error)'
                              : 'var(--color-black)',
                          }}
                        >
                          NOME FANTASIA E/OU CÓDIGO DA EMPRESA
                        </label>
                        <Grid container item xs={12}>
                          <Grid
                            item
                            md={9}
                            xs={12}
                            display="flex"
                            flexDirection="column"
                          >
                            <Controller
                              name="companies"
                              control={control}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <div className="flex flex-col w-full">
                                    <AsyncSelect
                                      unstyled={true}
                                      // isDisabled={disableStore}
                                      loadOptions={searchStoreOptions}
                                      styles={customStylesAsyncSelect}
                                      isMulti={false}
                                      error={true}
                                      value={valueStore}
                                      getOptionValue={(option) =>
                                        option.value
                                      }
                                      getOptionLabel={(option) =>
                                        option.label
                                      }
                                      onChange={handleSelectOption}
                                      placeholder="Digite aqui o nome fantasia ou código da concessionária"
                                      noOptionsMessage={() => ''}
                                      loadingMessage={() => ''}
                                    />
                                  </div>
                                );
                              }}
                            />
                            {errors.companies && (
                              <div className="errors-message">
                                <img src={RightCornerElement} />
                                <p className="error">
                                  {errors.companies.message}
                                </p>
                              </div>
                            )}
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={3}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              type="button"
                              variant={
                                (valueStore ? 'button-red' : 'button-gray') +
                                ' w-full mt-2 lg:mt-0 lg:ml-4 mx-auto'
                              }
                              style={{
                                marginBottom: errors?.companies
                                  ? '31px'
                                  : '0',
                              }}
                              text={
                                storesSelected.length > 0
                                  ? (user_role === 'Gerente de Pós-venda' ? 'ADICIONAR' : 'SUBSTITUIR')
                                  : 'ADICIONAR'
                              }
                              onClick={handleAddStore}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {storesSelected?.length > 0 && (
                      <>
                        <label
                          className="lg:text-[22px] text-[14px]"
                          style={{ margin: '12px 0px -3px 36px' }}
                        >
                          CONCESSIONÁRIA CADASTRADA
                        </label>

                        <Grid
                          item
                          xs={12}
                          sx={{ paddingTop: '0px !important' }}
                        >
                          <div className="register__stores-list">
                            {storesSelected?.map((item, index) => (
                              <div className="register__stores-list__item leading-[2]">
                                <p style={{ fontFamily: 'var(--bebas-bold)' }}>{item.label}</p>
                                {showCompaniesField && (
                                  <img
                                    src={TrashPink}
                                    onClick={() =>
                                      handleRemoveStoreSelected(item.value)
                                    }
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        </Grid>
                      </>
                    )}

                    <Grid item md={12} xs={12}>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputText
                                onChange={onChange}
                                value={value}
                                label="E-MAIL"
                                placeholder="Digite aqui seu e-mail"
                                errors={errors.email}
                                inputType="text"
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Controller
                        name="email_confirmation"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputText
                                onChange={onChange}
                                value={value}
                                label="CONFIRMAR E-MAIL"
                                placeholder="Confirme seu e-mail"
                                errors={errors.email_confirmation}
                                inputType="text"
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputText
                                onChange={onChange}
                                value={value}
                                label="ALTERAR SENHA"
                                inputPassword
                                placeholder="Alterare aqui sua senha"
                                errors={errors.password}
                                inputType="text"
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Controller
                        name="password_confirmation"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputText
                                onChange={onChange}
                                value={value}
                                inputPassword
                                label="CONFIRMAR NOVA SENHA"
                                placeholder="Confirme aqui sua nova senha"
                                errors={errors.password_confirmation}
                                inputType="text"
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div className="flex flex-col items-center justify-center lg:mt-5 mt-3">
                    <Button
                      type="submit"
                      variant="button-red w-72 mb-5 mx-auto"
                      text="ATUALIZAR DADOS"
                    />

                    <Button
                      onClick={() => navigate('/como-participar')}
                      variant="button-gray w-72 mb-6 mx-auto"
                      text="VOLTAR"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Background>
      </DefaultTemplate>
    </>
  );
};

export default EditProfile;
