import { useForm, Controller } from 'react-hook-form';
import { useContext, useRef, useState } from 'react';
import Button from '../../../../Components/Button';
import InputText from '../../../../Components/InputText';
import { useNavigate } from 'react-router-dom';
import { validateCPF } from '../../../Utils/validateCPF';
import { LoadingContext } from '../../../../Shared/LoadingContext';

import './LoginStyles.scss';

import ReCAPTCHA from 'react-google-recaptcha';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './schema';
import RecoverPassword from '../RecoverPassword';
import { UserContext } from '../../../../Shared/UserContext';
import { Profile, SignIn } from '../../../../Backend/auth';
import TemplateBackground from '../../../../Components/TemplateBackground';
import whatsApp from '../../../Media/Incentive/Icons/whatsappBaloon.svg';
import LoginTemplate from '../../Templates/LoginTemplate';
import { Grid } from '@mui/material';
import Init from '../../../Utils/Initializer';

const LoginForm = () => {
  Init('area-aberta', 'login');
  const [viewLogin, setViewLogin] = useState('login');
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({ resolver: yupResolver(schema) });
  const recaptchaRef = useRef(null);
  const { setLoading } = useContext(LoadingContext);
  const { setUserState } = useContext(UserContext);

  const makeLogin = async (data, recaptchaToken) => {
    setLoading(true);
    const response = await SignIn(data.cpf, data.password, recaptchaToken);

    if (response.success) {
      const responseProfile = await Profile();
      setUserState({ ...responseProfile.data.user, logged: true });
      localStorage.setItem('logged', 'isLogged');
      localStorage.setItem('name', responseProfile.data.user.firstName);

      if(responseProfile.data.user.user_role_id === 10) {
        navigate('/painel-gestor-de-pontos');
      } else {
        navigate('/como-participar');
      }

      setLoading(false);
    } else {
      setError('cpf', { type: 'manual', message: response.data.login[0] });
      setError('password', {
        type: 'manual',
        message: response.data.password[0],
      });
      setLoading(false);
    }
  };

  const handleCustomSubmit = (data) => {
    const cpfIsValid = validateCPF(data.cpf);
    if (!cpfIsValid) {
      setError('cpf', { type: 'manual', message: 'CPF inválido' });
    } else {
      recaptchaRef.current.executeAsync().then((recaptchaToken) => {
        recaptchaRef.current.reset();
        makeLogin(data, recaptchaToken);
      });
    }
  };

  return (
    <>
      <LoginTemplate>
        <TemplateBackground />
        <div className="flex justify-center items-center lg:mt-16 lg:mb-10 lg:gap-11">
          {viewLogin === 'login' && (
            <div className="">
              <form onSubmit={handleSubmit(handleCustomSubmit)}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                />

                <div className="login mt-7 lg:mt-0">
                  <h1 className="text-center mb-3 mt-5">
                    <strong>Cadastre-se</strong> e acumule conquistas!
                  </h1>

                  <Grid container spacing={1}>
                    <Grid item md={12} xs={12}>
                      <Controller
                        name="cpf"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <InputText
                            label="CPF"
                            placeholder="Digite aqui seu CPF"
                            onChange={onChange}
                            value={value}
                            errors={errors.cpf}
                            mask="999.999.999-99"
                            inputMode="numeric"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <>
                              <InputText
                                onChange={onChange}
                                value={value}
                                label="SENHA"
                                inputPassword
                                placeholder="Digite aqui sua senha"
                                errors={errors.password}
                                inputType="text"
                              />
                            </>
                          );
                        }}
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      marginBottom: '8px',
                      marginTop: '25px',
                      width: '100%',
                    }}
                  >
                    <Button
                      type="submit"
                      variant="button-red w-full"
                      textLoading="ENTRANDO..."
                      text="ENTRAR"
                    />
                  </div>

                  <Button
                    type="button"
                    variant="outlined mx-auto"
                    text="ESQUECI MINHA SENHA"
                    onClick={() => setViewLogin('recover-password')}
                  />
                </div>
              </form>
              <div className="login__register">
                <h3>Ainda não é cadastrado?</h3>
                <Button
                  type="submit"
                  variant="button-red w-full"
                  onClick={() => navigate('/cadastre-se')}
                  text="CADASTRE-SE"
                />
                <a
                  href="https://wa.me/5511966395573"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="flex w-[286px] px-[32px] items-center gap-[11px] bg-white h-[71px] rounded-[12px] mt-[15px]">
                    <img src={whatsApp} alt="WhatsApp" width={46} />
                    <div className="flex flex-col gap-[4px]">
                      <p className="text-[20px] font-bold text-[#0A0C14] leading-[15px]">
                        Central de atendimento
                      </p>
                      <p className="text-[#CC0000] text-[22px] font-bold  leading-[24px]">
                        (11) 96639-5573
                      </p>
                      <p className="text-[14px] text-[#0A0C14] leading-[10px] font-bold">
                        Apenas mensagens de texto ou áudio.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          )}
          {viewLogin === 'recover-password' && (
            <RecoverPassword setViewLogin={setViewLogin} />
          )}
        </div>
      </LoginTemplate>
    </>
  );
};

export default LoginForm;
