import { useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';
import DefaultTemplate from '../../Templates/DefaultTemplate';
import BackgroundPages from '../../../../Components/BackgroundPages';
import { LoadingContext } from '../../../../Shared/LoadingContext';
import TotalCards from './TotalCards';
import TablePoints from './TablePoints';
import './PanelManagerStyles.scss';
import Init from "../../../Utils/Initializer";
import { UserContext } from '../../../../Shared/UserContext';
import TableExtract from './TableExtract';
import PanelManagerSuccess from '../PanelManagerSuccess';
import { GetPoints, SetCompany } from '../../../../Backend/PanelManager';

const PanelManager = () => {
    Init('area-logada', 'painel-gestor-de-pontos');
    const [detailIndex, setDetailIndex] = useState(0);
    const [results, setResults] = useState([]);
    const { setLoading } = useContext(LoadingContext);
    const [classcard, setClassCard] = useState(detailIndex);
    const navigate = useNavigate();
    const [success, setSuccess] = useState(false);

    const [storeActive, setStoreActive] = useState('');
    const [stores, setStores] = useState([]);
    const [undistributedPoints, setUndistributedPoints] = useState(0);
    const [distributedPoints, setDistributedPoints] = useState(0);
    const [monthsUndistributed, setMonthsUndistributed] = useState([]);
    const [monthsDistributed, setMonthsDistributed] = useState([]);

    const [selectedMonthLabel, setSelectedMonthLabel] = useState('');
    const [monthPoints, setMonthPoints] = useState(0);

    const handleSetDetailIndex = (id) => {
        setDetailIndex((prevIndex) => (prevIndex === id ? 0 : id));
        setClassCard(id);
    };

    const name = localStorage.getItem('name');

    const formattedName = name?.charAt(0).toUpperCase() + name?.slice(1).toLowerCase();

    useEffect(() => {
        setClassCard(detailIndex);
    }, [detailIndex]);

    useEffect(() => {
        const GetPointsData = async () => {

            setLoading(true);

            const response = await GetPoints();

            setStoreActive(response.data?.storeActive);
            setStores(response.data?.stores);
            setUndistributedPoints(response.data?.totalUndistributedPoints);
            setDistributedPoints(response.data?.totalDistributedPoints);
            setMonthsUndistributed(response.data?.monthsUndistributedPoints);
            setMonthsDistributed(response.data?.monthsDistributedPoints);

            setLoading(false);
        };

        GetPointsData();
    }, []);

    const changeCompany = (company) => {

        const SetCompanyData = async (company) => {

            setLoading(true);

            const response = await SetCompany(company);
            window.location.reload();

            setLoading(false);
        };

        SetCompanyData(company);
    };

    return (
        <>
            <DefaultTemplate>
                {success ? (
                    <>
                        <PanelManagerSuccess selectedMonthLabel={selectedMonthLabel} monthPoints={monthPoints} />
                    </>
                ) : (
                    <>
                        <BackgroundPages variation="background-result background-panel lg:pb[150px] pb-[48px]">
                            <div className="panel">
                                <h1 className="text-[#CC0000] lg:text-[40px] text-[32px] lg:mt-[52px] mt-20">
                                    PAINEL GESTOR DE PONTOS
                                </h1>
                                <p className='lg:text-[28px] text-[20px]'>
                                    <strong>Distribua</strong> a pontuação e <strong>incentive</strong> sua equipe!
                                </p>
                                <div className='flex flex-col justify-center items-center bg-white rounded-[20px] shadow-md lg:w-[722px] w-[320px] lg:h-[173px] h-[222px] mx-auto text-center relative z-10 mt-4'>
                                    <p className='lg:text-[28px] text-[20px] leading-none'><strong className='text-[#CC0000]'>
                                        {typeof formattedName !== "undefined" ? formattedName : ""}, contamos<br className='lg:hidden' /> com você</strong> para alcançarmos<br className='lg:hidden' /> incríveis resultados!
                                    </p>
                                    <p className='lg:text-[28px] text-[20px] leading-none lg:mt-6 mt-4'>
                                        <strong className='text-[#CC0000]'>Acesse</strong> este painel{' '}
                                        <strong className='text-[#CC0000]'>mensalmente</strong>,
                                        <br className='lg:hidden' /> confira a <strong className='text-[#CC0000]'>pontuação</strong>{' '}
                                        conquistada<br className='lg:hidden' /> pela sua
                                        {' '}<strong className='text-[#CC0000]'>concessionária</strong>{' '}
                                        e credite<br className='lg:hidden' /> os pontos para cada vendedor{' '}<br className='lg:hidden' />
                                        e consultor de serviços.
                                    </p>
                                </div>

                                {stores.length > 1 &&
                                    <div className="inputs-container lg:w-[446px] w-[286px] mt-8">
                                        <label className="pl-8">
                                            Selecione a concessionária:
                                        </label>
                                        <select
                                            className="input-field first-option-placeholder rounded-xl md:h-10 xl:h-14"
                                            id="selectMonth"
                                            onChange={(e) => changeCompany(e.target.value)}
                                        >
                                            {stores.map((item) => (
                                                <option key={item.store_uuid} value={item.store_uuid} selected={(item.store_uuid === storeActive)}>
                                                    ({item.store_code}) {item.store}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                }

                                <section>
                                    {/* {loadingCards ? (
                            <div className="w-96 flex items-center justify-center h-64">
                                <FaSpinner className="animate-spin mr-2" />
                            </div> */}
                                    {/* ) : ( */}
                                    <>
                                        <div className="flex flex-col lg:gap-[3.5rem] lg:mt-12 mt-6 lg:flex-row items-center justify-center">
                                            <TotalCards
                                                value={undistributedPoints || 0}
                                                hasButton
                                                showButton={(undistributedPoints > 0 ? true : false)}
                                                detailComponent={<TablePoints />}
                                                setDetailIndex={setDetailIndex}
                                                id={1}
                                                openDetail={detailIndex === 1}
                                                descriptionValue={
                                                    <>
                                                        <span className='text-[#0A0C14] text-[24px] leading-none mb-3' style={{ fontFamily: 'var(--bebas-bold)' }}>
                                                            TOTAL DE PONTOS<br />
                                                            PARA CREDITAR
                                                        </span>
                                                    </>
                                                }
                                                results={results}
                                                onClick={() => handleSetDetailIndex(1)}
                                                classcard={classcard}
                                            />

                                            <>
                                                {/* mobile table */}
                                                <div className='lg:hidden'>
                                                    {detailIndex === 1 &&
                                                        <TablePoints
                                                            monthsUndistributed={monthsUndistributed}
                                                            setSuccess={setSuccess}
                                                            setSelectedMonthLabel={setSelectedMonthLabel}
                                                            setMonthPoints={setMonthPoints}
                                                        />
                                                    }
                                                </div>
                                            </>

                                            <TotalCards
                                                value={distributedPoints || 0}
                                                hasButton
                                                showLink={(distributedPoints > 0 ? true : false)}
                                                setDetailIndex={setDetailIndex}
                                                detailComponent={<TableExtract />}
                                                id={2}
                                                openDetail={detailIndex === 2}
                                                descriptionValue={
                                                    <>
                                                        <span className='text-[#0A0C14] text-[24px] leading-none mb-3' style={{ fontFamily: 'var(--bebas-bold)' }}>
                                                            PONTOS
                                                            <br />
                                                            JÁ DISTRIBUÍDOS
                                                        </span>
                                                    </>
                                                }
                                                results={results}
                                                onClick={() => handleSetDetailIndex(2)}
                                                classcard={classcard}
                                                classMargin
                                            />

                                            <>
                                                {/* mobile table */}
                                                <div className='lg:hidden'>
                                                    {detailIndex === 2 && <TableExtract monthsDistributed={monthsDistributed} />}
                                                </div>
                                            </>
                                        </div>
                                    </>
                                    {/* )} */}
                                    {/* {loadingCards ? (
                                <div className="w-96 flex items-center justify-center h-64">
                                <FaSpinner className="animate-spin mr-2" />
                                </div>
                            ) : ( */}
                                    <>
                                        <div className='hidden lg:block'>
                                            {detailIndex === 1 &&
                                                <TablePoints
                                                    monthsUndistributed={monthsUndistributed}
                                                    setSuccess={setSuccess}
                                                    setSelectedMonthLabel={setSelectedMonthLabel}
                                                    setMonthPoints={setMonthPoints}
                                                />
                                            }
                                            {detailIndex === 2 && <TableExtract monthsDistributed={monthsDistributed} />}
                                        </div>
                                    </>
                                    {/* )
                            } */}
                                </section>
                            </div>
                        </BackgroundPages>
                    </>
                )}
            </DefaultTemplate >
        </>
    )
}

export default PanelManager;