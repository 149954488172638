import Client, { Response } from './Client';
import { request } from "axios";

export async function Login(username, password, recaptchaToken) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/login', {
        login: username,
        password,
        'g-recaptcha-response': recaptchaToken
      });

      resolve({
        success: true,
        code: response.status,
        data: response.data
      });
    } catch (error) {
      resolve({
        success: false,
        code: error.response.status,
        data: error.response.data || null
      });
    }
  });
}

export async function Register({
  cpf,
  name,
  mobile,
  birth_at,
  sex,
  state,
  role,
  email,
  email_confirmation,
  password,
  password_confirmation,
  optin_rules,
  optin_privacy,
  companies,
  recaptchaToken
}) {

  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.post('/incentive/register', {
        cpf,
        name,
        mobile,
        birth_at,
        sex,
        state,
        role,
        email,
        email_confirmation,
        password,
        password_confirmation,
        optin_rules,
        optin_privacy,
        companies,
        "g-recaptcha-response": recaptchaToken,
      });
      resolve({
        success: true,
        code: response.status,
        data: response.data
      });
    } catch (error) {
      resolve({
        success: false,
        code: error.response.status,
        data: error.response.data || null
      });
    }
  });

}

export async function ProfileUpdate(
  name,
  mobile,
  birth_at,
  sex,
  state,
  email,
  email_confirmation,
  password,
  password_confirmation,
  companies
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.put('/incentive/profile', {
        name,
        mobile,
        birth_at,
        sex,
        state,
        email,
        email_confirmation,
        password,
        password_confirmation,
        companies
      });

      resolve({
        success: true,
        code: response.status,
        data: response.data
      });
    } catch (error) {
      resolve({
        success: false,
        code: error.response.status,
        data: error.response.data || null
      });
    }
  });
}

export async function Profile() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/profile');

      resolve({
        success: true,
        code: response.status,
        data: response.data
      });
    } catch (error) {
      resolve({
        success: false,
        code: error.response.status,
        data: error.response.data || null
      });
    }
  });
}

export async function RegisterEnabled() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/register/enabled');
      resolve({
        success: true,
        code: response.status,
        data: response.data
      });
    } catch (error) {
      resolve({
        success: false,
        code: error.response.status,
        data: error.response.data || null
      });
    }
  });
}

// export async function Logout, clean all cookies in a promise and redirect to login page
export async function Logout() {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await Client.get('/incentive/logout');
      resolve({
        success: true,
        code: response.status,
        data: response.data
      });
    } catch (error) {
      resolve({
        success: false,
        code: error.response.status,
        data: error.response.data || null
      });
    }
  });
}