import * as Yup from "yup";
import { validateCPF } from "../../../Utils/validateCPF";

export const schema = Yup.object({
    cpf: Yup.string().required('Esta informação é necessária.').test('ValidateCPF', 'Insira um CPF válido.', (value) => {
        const isvalid = validateCPF(value);
        return isvalid;
    }),

    name: Yup.string().required('Esta informação é necessária.').test('VerificaSobrenome', 'Digite seu nome completo.', (value) => {
        const hasSobrenome = !!value.split(' ')[1];
        return hasSobrenome;
    }),

    birth_at: Yup.string().required('Esta informação é necessária.'),

    mobile: Yup.string().required('Esta informação é necessária.'),
    sex: Yup.string().required('Esta informação é necessária.'),
    state: Yup.string().required('Esta informação é necessária.'),
    email: Yup.string().required('Esta informação é necessária.').email('Insira um e-mail válido.'),

    email_confirmation: Yup.string()
        .required('Esta informação é necessária.')
        .oneOf([Yup.ref('email')], 'Os e-mails não correspondem.'),

    password: Yup.string()
        .min(6, 'Sua senha deve ter mais de 6 caracteres.')
        .required('Esta informação é necessária.'),

    password_confirmation: Yup.string()
        .required('Esta informação é necessária.')
        .oneOf([Yup.ref('password')], 'As senhas não correspondem.'),

    optin_rules: Yup.boolean()
        .required('Esta informação é necessária.')
        .oneOf([true], 'Aceite o regulamento e receber informações para prosseguir.'),

    optin_privacy: Yup.boolean()
        .required('Esta informação é necessária.')
        .oneOf([true], 'Aceite os termos de políticas de privacidade para prosseguir.'),

    companies: Yup.string().required('Esta informação é necessária.'),
    role: Yup.string().required('Esta informação é necessária.'),
});