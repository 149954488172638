import { createContext, useEffect, useState } from 'react';
import { Profile } from '../Backend/auth';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userState, setUserState] = useState({});
  const getUserData = async () => {
    const response = await Profile();

    if (response.success) {
      setUserState(response.data.user);
      localStorage.setItem('logged', 'isLogged');
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <UserContext.Provider value={{ setUserState, userState }}>
      {children}
    </UserContext.Provider>
  );
};
