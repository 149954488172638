import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { validateCPF } from '../../../../Utils/validateCPF';
import { LoadingContext } from '../../../../../Shared/LoadingContext';
import { Register } from '../../../../../Backend/User';
import { UserContext } from '../../../../../Shared/UserContext';

const useHandlersRegister = ({
  setValue,
  setError,
  recaptchaRef,
  setScrollToErrorUpdate,
  scrollToErrorUpdate,
  setSuccess,
  storesSelected,
}) => {
  const { setLoading } = useContext(LoadingContext);
  const { setUserState } = useContext(UserContext);
  const navigate = useNavigate();

  const clearFields = () => {
    setValue('name', '');
    setValue('email', '');
    setValue('city', '');
    setValue('role', '');
    setValue('email_confirmation', '');
    setValue('birth_at', '');
    setValue('mobile', '');
    setValue('sex', '');
    setValue('password', '');
    setValue('password_confirmation', '');
  };

  const handleCustomSubmit = (data) => {
    const cpfIsValid = validateCPF(data.cpf);
    if (!cpfIsValid) {
      setError('cpf', { type: 'manual', message: 'CPF inválido' });
    } else {
      recaptchaRef.current.executeAsync().then((recaptchaToken) => {
        setLoading(true);
        recaptchaRef.current.reset();
        onSubmit(data, recaptchaToken);
      });
    }
  };

  const onSubmit = async (data, recaptchaToken) => {
    const response = await Register({
      cpf: data.cpf,
      name: data.name,
      mobile: data.mobile,
      birth_at: data.birth_at,
      sex: data.sex,
      city: data.city,
      state: data.state,
      role: data.role,
      companies:
        storesSelected.length > 0
          ? storesSelected.map((item) => item.value)
          : undefined,
      email: data.email,
      email_confirmation: data.email_confirmation,
      password: data.password,
      password_confirmation: data.password_confirmation,
      optin_rules: data.optin_rules,
      optin_privacy: data.optin_privacy,
      recaptchaToken: recaptchaToken,
    });

    if (response.success) {
      setUserState({ ...response.data.user, logged: true, res_group: true });
      localStorage.setItem('logged', 'isLogged');
      localStorage.setItem('name', response.data.user.firstName);
      setLoading(false);
      setSuccess(true);

      if (response.data.user.user_role_id === 10) {
        navigate('/painel-gestor-de-pontos');
      } else {
        navigate('/sucesso');
      }

    } else {
      setLoading(false);
      for (const key in response.data.errors) {
        if (response.data.errors.hasOwnProperty(key)) {
          const error = response.data.errors[key][0];
          setError(key, { type: 'manual', message: error });
          setScrollToErrorUpdate(scrollToErrorUpdate + 1);
        }
      }
    }
  };

  return {
    clearFields,
    handleCustomSubmit,
  };
};

export default useHandlersRegister;
