import './InputStyles.scss';
import RightCornerElement from '../../Resources/Media/Incentive/Icons/triangle.png';
import { FaSpinner } from 'react-icons/fa';

const InputSelect = (props) => {
  return (
    <>
      <div
        className={`${props.errors ? 'error' : ''} 
         inputs-container ${props.classInputContainer}`}
      >
        <label className="px-5">{props.label}</label>
        <div
          className={`${props.errors ? 'error' : ''
            } input-field rounded-xl md:h-10 xl:h-14`}
        >
          <select
            {...props}
            className={
              props.value === '' || !props.value
                ? 'first-option-placeholder'
                : ''
            }
          >
            <option value="">Selecione</option>
            {props.optionsSelect?.map((option) => (
              <option value={props.requestInOnChange ? option : option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {props.loadingInput && <FaSpinner className="animate-spin mr-2" />}
        </div>
        {props.errors && (
          <div className="errors-message">
            <img src={RightCornerElement} alt="" />
            <i className="error">{props.errors.message}</i>
          </div>
        )}
      </div>
    </>
  );
};

export default InputSelect;
