import { useNavigate } from 'react-router-dom';
import { Profile } from '../../Backend/auth';
import { useContext } from 'react';
import { UserContext } from '../../Shared/UserContext';
import { LoadingContext } from '../../Shared/LoadingContext';

const useVerifyIfIsLogged = () => {
  const navigate = useNavigate();
  const { setLoading } = useContext(LoadingContext);
  const { setUserState } = useContext(UserContext);
  const verifyIfIsLogged = async (setShowScreen) => {
    setLoading(true);
    const response = await Profile();

    if (response.success) {
      setUserState({ ...response.data.user, logged: true });
      localStorage.setItem('logged', 'isLogged');
      localStorage.setItem('user_role', response.data.user.user_role_id);
      setShowScreen(true);
      setLoading(false);
    } else {
      localStorage.removeItem('isLogged');
      localStorage.removeItem('sex');
      localStorage.removeItem('name');
      localStorage.removeItem('user_role');
      navigate('/');
      setLoading(false);
    }

    return response;
  };

  return { verifyIfIsLogged };
};

export default useVerifyIfIsLogged;
