import { useEffect, useState } from 'react';
import DefaultTemplate from '../../Templates/DefaultTemplate';
import './HomeStyles.scss';
import lubricant from '../../../Media/Incentive/Pages/Home/lubricant.webp';
import selo from '../../../Media/Common/selo.webp';
import cart from '../../../Media/Incentive/Icons/cart.svg';
import money from '../../../Media/Incentive/Icons/money.svg';
import gift from '../../../Media/Incentive/Icons/gift.svg';

import AOS from 'aos';
import 'aos/dist/aos.css';
import ButtonFixed from '../../../../Components/ButtonFixed';
import Init from "../../../Utils/Initializer";
import { GetSaldo } from '../../../../Backend/MyResults';

const Home = () => {
  Init('area-logada', 'como-participar');
  const saldoValue = localStorage.getItem('saldo');

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const getSaldoNumber = async () => {
      const response = await GetSaldo();
      localStorage.setItem('saldo', response.data.points);
    };
    getSaldoNumber();
  }, []);

  return (
    <>
      <DefaultTemplate removeBackground>
        <section className='home'>
          <div className='home-content'>
            <div className='home-content-banner'>
              <div>
                <picture>
                  <img
                    className='lg:w-[467px] w-[290px] lg:m-0 mx-auto lg:ml-16'
                    src={selo}
                    alt="Pro Honda de Vantagens"
                    data-aos="zoom-out-down"
                    data-aos-duration="1500"
                  />
                </picture>
              </div>
              <div className='relative lg:left-[50px] lg:mt-10 mt-8'>
                <div>
                  <p className='lg:text-[42px] text-[28px] lg:text-start text-center leading-[0.9] lg:w-[235px]' style={{ fontFamily: 'var(--bebas-light)' }}>
                    Acumule muitas<br />
                    <strong>conquistas</strong>{' '}com{' '}
                    <strong>Pro Honda.</strong>
                  </p>
                </div>
                <div className='lg:block hidden'>
                  <h1 className='font-bold lg:text-[78px] text-[50px] leading-[0.9] mt-6'>
                    VENDEU,<br />
                    <strong>GANHOU!</strong>
                  </h1>
                </div>
              </div>
              <div className='flex justify-center lg:mt-6 mt-3'>
                <div className='lg:hidden relative left-12'>
                  <h1 className='font-bold lg:text-[78px] text-[50px] leading-none mt-10'>
                    VENDEU,<br />
                    <strong>GANHOU!</strong>
                  </h1>
                </div>
                <div className='relative lg:top-[75px] xl:left-[0] left-[25px] lg:mt-0 mt-4'>
                  <picture>
                    <img
                      src={lubricant}
                      className='lg:w-full w-[271px]'
                      alt="Lubrificante para correntes Pro Honda"
                      data-aos="zoom-in"
                      data-aos-duration="1500"
                    />
                  </picture>
                </div>
              </div>
            </div>
            <div className='home-content-howto lg:pt-[150px]'>
              <div className='flex lg:flex-row flex-col justify-center lg:items-start items-center lg:gap-[5rem] gap-7 lg:mt-[20px] -mt-[36px]'>
                <div
                  data-aos="zoom-out-up"
                  data-aos-duration="1500"
                >
                  <picture>
                    <img
                      src={cart}
                      className='lg:w-[137px] w-[114px]'
                      alt='Venda kit lubrificante pro honda'
                    />
                  </picture>
                  <div className='text-center'>
                    <h1 className='lg:text-[40px] text-[32px] text-[#cc0000] leading-tight'>VENDA</h1>
                    <p className='lg:text-[28px] text-[24px] text-[#000] leading-none'>
                      Kit Lubrificante<br />
                      Pro Honda
                    </p>
                  </div>
                </div>
                <div
                  data-aos="zoom-out-up"
                  data-aos-duration="1500"
                >
                  <picture>
                    <img
                      src={money}
                      className='lg:w-[137px] w-[114px]'
                      alt='Ganhe pontos'
                    />
                  </picture>
                  <div className='text-center'>
                    <h1 className='lg:text-[40px] text-[32px] text-[#cc0000] leading-tight'>GANHE</h1>
                    <p className='lg:text-[28px] text-[24px] text-[#000] leading-none'>
                      pontos
                    </p>
                  </div>
                </div>
                <div
                  data-aos="zoom-out-up"
                  data-aos-duration="1500"
                >
                  <picture>
                    <img
                      src={gift}
                      className='lg:w-[137px] w-[114px]'
                      alt='Troque por prêmios incríveis'
                    />
                  </picture>
                  <div className='text-center'>
                    <h1 className='lg:text-[40px] text-[32px] text-[#cc0000] leading-tight'>TROQUE</h1>
                    <p className='lg:text-[28px] text-[24px] text-[#000] leading-none'>
                      por prêmios<br />
                      incríveis
                    </p>
                  </div>
                </div>
              </div>
              <div className='mt-8'
                data-aos="zoom-in"
                data-aos-duration="2500"
              >
                <h1 className='text-center lg:text-[42px] text-[26px] leading-[1.2]' style={{ fontFamily: 'var(--bebas-light)' }}>
                  QUANTO MAIS VOCÊ VENDE,
                </h1>
                <div className='flex justify-center items-center lg:w-[410px] w-[269px] lg:h-[50px] h-[30px] rounded-full border-[1px] border-black px-4 mx-auto'>
                  <h1 className='lg:text-[42px] text-[26px] text-[#cc0000] leading-[1.2]'>
                    MAIS PONTOS VOCÊ ACUMULA!
                  </h1>
                </div>
              </div>
              <div>
                <div className='mx-auto bg-[#868889] lg:w-[586px] w-[320px] rounded-full py-2 lg:mt-12 mt-8 lg:mb-10 mb-6'>
                  <h4 className='text-white lg:text-[24px] text-[20px] text-center leading-none'>
                    Campanha válida de janeiro<br className='lg:hidden' /> a dezembro de 2024
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>
        {typeof saldoValue !== "undefined" && saldoValue !== "0" && saldoValue !== "-" ? (
          <ButtonFixed
            name="RESGATAR PRÊMIOS"
          />
        ) : null}
      </DefaultTemplate>
    </>
  )
}

export default Home;