import BackgroundPages from '../../../../Components/BackgroundPages';
import DefaultTemplate from '../../Templates/DefaultTemplate';
import Whatsapp from '../../../Media/Incentive/Icons/whatsapp.svg';
import { Link } from "react-router-dom";
import Init from "../../../Utils/Initializer";

const Contact = () => {
    Init('area-logada', 'fale-conosco');
    return (
        <>
            <DefaultTemplate>
                <BackgroundPages variation="background-full">
                    <section className='lg:mt-20'>
                        <a href="https://wa.me/5511966395573&text=Ol%C3%A1%2C+preciso+falar+sobre+a+Campanha+Pro+Honda+de+Vantagens" target='_blank'>
                            <div className='flex flex-col justify-center items-center bg-white rounded-[20px] shadow-md lg:w-[802px] w-[320px] lg:h-[358px] h-[374px] relative z-10 text-center'>
                                <h1
                                    className='lg:text-[30px] text-[24px] leading-none'
                                    style={{ fontFamily: 'var(--bebas-bold)' }}
                                >
                                    TEM ALGUMA <strong className='text-[#CC0000]'>DÚVIDA?</strong>
                                </h1>
                                <p
                                    className='lg:text-[24px] text-[18px] leading-none'
                                    style={{ fontFamily: 'var(--bebas-bold)' }}
                                >
                                    <strong className='text-[#CC0000]'>Entre em contato</strong> com a gente!
                                </p>
                                <div className='flex justify-center items-center gap-3 mt-8'>
                                    <div>
                                        <picture>
                                            <img src={Whatsapp} alt="Whatsapp" />
                                        </picture>
                                    </div>
                                    <div className='flex flex-col justify-center'>
                                        <h1
                                            className='lg:text-[38px] text-[30px] leading-none'
                                            style={{ fontFamily: 'var(--bebas-bold)' }}
                                        >
                                            (11) 96639-5573
                                        </h1>
                                        <p
                                            className='text-[#CC0000] lg:text-[24px] text-[18px] leading-none'
                                            style={{ fontFamily: 'var(--bebas-bold)' }}
                                        >
                                            Apenas mensagens<br />
                                            de texto ou áudio.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </section>
                </BackgroundPages>
            </DefaultTemplate>
        </>
    )
}
export default Contact;
