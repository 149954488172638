export const NoChecked = ({ error }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
    >
      <g
        id="Elipse_122"
        data-name="Elipse 122"
        fill="#fff"
        stroke="#C9C9C9"
        stroke-width="1"
      >
        <circle cx="14.5" cy="14.5" r="14.5" stroke="none" />
        <circle cx="14.5" cy="14.5" r="14" fill="none" />
      </g>
    </svg>
  );
};

export const Checked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
    >
      <g
        id="Grupo_5251"
        data-name="Grupo 5251"
        transform="translate(-323 -1058)"
      >
        <g
          id="Elipse_122"
          data-name="Elipse 122"
          transform="translate(323 1058)"
          fill="#fff"
          stroke="#C9C9C9"
          stroke-width="1"
        >
          <circle cx="14.5" cy="14.5" r="14.5" stroke="none" />
          <circle cx="14.5" cy="14.5" r="14" fill="none" />
        </g>
        <path
          id="Icon_feather-check"
          data-name="Icon feather-check"
          d="M21.288,9,10.777,19.51,6,14.733"
          transform="translate(323.856 1058.245)"
          fill="none"
          stroke="#CC0000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};
