import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Styles/main.scss';
import { BrowserRouter } from 'react-router-dom';
import { LoadingProvider } from './Shared/LoadingContext';
import { UserProvider } from './Shared/UserContext';
import { OverlayProvider } from "./Shared/OverlayContext";

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <BrowserRouter>
      <OverlayProvider>
        <LoadingProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </LoadingProvider>
      </OverlayProvider>
    </BrowserRouter>
  </React.StrictMode>
);
