import * as Yup from 'yup';

export const schema = Yup.object({
  password: Yup.string()
    .min(6, 'Sua senha deve ter mais de 6 caracteres.')
    .required('Esta informação é necessária.'),

  password_confirmation: Yup.string()
    .required('Esta informação é necessária.')
    .oneOf([Yup.ref('password')], 'As senhas não correspondem.'),
});
