import { useContext, useEffect, useState } from 'react';
import Footer from '../../../../Components/Footer';
import Header from '../../../../Components/Header';
import useVerifyIfIsLogged from '../../../Utils/useVerifyIfIsLogged';
import { LoadingContext } from '../../../../Shared/LoadingContext';

const LoginTemplate = (props) => {
  const [showScreen, setShowScreen] = useState();
  const { verifyIfIsLogged } = useVerifyIfIsLogged();
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    verifyIfIsLogged(setShowScreen);
  }, []);

  return (
    <>
      {showScreen === true && (
        <>
          <Header />
          {props.children}
          <Footer hasButtonFixed={props.hasButtonFixed} />
        </>
      )}
    </>
  );
};

export default LoginTemplate;
