import { React } from "react";

const TableWinner = (props) => {

    return (
        <>
            <div className='bg-white rounded-[20px] lg:w-[919px] mx-auto w-[316px] shadow-md lg:py-5 py-3 lg:mt-11 mt-[-25px]'>
                <div className="flex items-center justify-center">
                    <div className="hidden lg:block">
                        <table className="">
                            <tr>
                                <td className='bg-[#CC0000] text-white text-[24px]'>Mês</td>
                                <td className='bg-[#CC0000] text-white text-[24px]'>Qtd. Vendas</td>
                                <td className='bg-[#CC0000] text-white text-[24px]'>Pontos</td>
                            </tr>
                            {props.results?.map((item) => (
                                <tr>
                                    <td>{item.month}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.points}</td>
                                </tr>
                            ))}
                        </table>
                    </div>

                    {/* mobile firt table */}
                    <div className="lg:hidden">
                        {props.results?.map((item) => (
                            <table>
                                <tr>
                                    <td className='bg-[#CC0000] text-white text-[22px] rounded-t-[15px]'>Mês</td>
                                </tr>
                                <tr>
                                    <td>{item.month}</td>
                                </tr>
                                <tr>
                                    <td className='bg-[#CC0000] text-white text-[22px]'>Qtd. Vendas</td>
                                </tr>
                                <tr>
                                    <td>{item.amount}</td>
                                </tr>
                                <tr>
                                    <td className='bg-[#CC0000] text-white text-[22px]'>Pontos</td>
                                </tr>
                                <tr>
                                    <td>{item.points} pontos</td>
                                </tr>
                            </table>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TableWinner;