export const scrollToFirstError = () => {
  const headerHeight = document.querySelector('.header') ? document.querySelector('.header').offsetHeight : 0
  const firstErrorField = document.querySelector('.error');

  if (firstErrorField) {
    const errorFieldPosition = firstErrorField.getBoundingClientRect().top;
    const scrollPosition = errorFieldPosition - headerHeight;
    window.scrollBy({
      top: scrollPosition,
      behavior: 'smooth',
    });
  }
};
