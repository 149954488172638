import { useEffect } from "react";
import ButtonFixed from "../../../../Components/ButtonFixed";
import DefaultTemplate from "../../Templates/DefaultTemplate";
import kit from "../../../Media/Incentive/Pages/Home/lubricant.webp";
import brands from "../../../Media/Incentive/Pages/Award/brands.webp";
import point from "../../../Media/Incentive/Pages/Award/point.webp";

import "./AwardStyles.scss";

import AOS from 'aos';
import 'aos/dist/aos.css';
import Init from "../../../Utils/Initializer";

const Award = () => {
    Init('area-logada', 'premiacao');
    const userRole = localStorage.getItem('user_role');

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <DefaultTemplate>
                <section className="award">
                    <div
                        data-aos="zoom-out-down"
                        data-aos-duration="1500"
                    >
                        <div className="flex justify-center items-center">
                            <div>
                                <h1 className="lg:text-[44px] text-[39px] leading-[0.9] -mr-4 mt-4 ml-5">
                                    VENDEU,<br />
                                    <strong className="text-[#CC0000]">GANHOU!</strong>
                                </h1>
                            </div>
                            <div>
                                <picture>
                                    <img
                                        src={kit}
                                        alt="Kit Oleo Lubrificante"
                                        className="lg:w-[157px] w-[144px] lg:mt-8 mt-[2.5rem]"
                                    />
                                </picture>
                            </div>
                        </div>
                        <div className="flex justify-center items-center border-[1px] border-black rounded-full lg:w-[437px] w-[282px] lg:h-[39px] h-[31px] mx-auto -mt-8">
                            <h2 className="lg:text-[28px] text-[20px]">1 Kit Lubrificante Pro Honda = <strong className="text-[#CC0000]">3 pontos</strong></h2>
                        </div>
                    </div>
                    <div className="flex lg:flex-row flex-col justify-center lg:items-start items-center gap-7 lg:mt-8 mt-6">
                        <div>
                            <h1 className="lg:text-[24px] text-[18px] text-[#CC0000] lg:mt-2">
                                MAIS DE 1 MILHÃO DE OPÇÕES DE PRÊMIOS
                            </h1>
                            <ul className="lg:leading-[1.3] leading-[1.6]">
                                <li><span>•</span>{' '}Pagamento de contas</li>
                                <li><span>•</span>{' '}Vale-combustível</li>
                                <li><span>•</span>{' '}Eletrodomésticos e eletrônicos</li>
                                <li><span>•</span>{' '}Casa e cozinha</li>
                                <li><span>•</span>{' '}Beleza e perfumaria</li>
                                <li><span>•</span>{' '}Vales-compras</li>
                                <li><span>•</span>{' '}Vouchers virtuais</li>
                                <li><span>•</span>{' '}E muito mais</li>
                            </ul>
                            <div className="award-premius z-10">
                                <picture>
                                    <img
                                        className="mt-4 lg:mx-0 mx-auto"
                                        src={point}
                                        width={160}
                                        alt="1 Ponto = R$ 1,00"
                                    />
                                </picture>
                            </div>
                        </div>
                        <div
                            data-aos="fade-left"
                            data-aos-duration="1500"
                        >
                            <picture>
                                <img
                                    className="lg:w-[590px] w-[289px]"
                                    src={brands}
                                    alt="Marcas"
                                />
                            </picture>
                        </div>
                    </div>
                </section>

                {userRole !== '10' &&
                <ButtonFixed
                    name="ACESSAR PRÊMIOS"
                />
                }

            </DefaultTemplate>
        </>
    )
};
export default Award;