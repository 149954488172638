import { useNavigate } from 'react-router-dom';
import Background from '../../../../Components/Background';
import Button from '../../../../Components/Button';

import '../Register/RegisterStyles.scss';

import icon from '../../../Media/Incentive/Icons/success.svg';
import DefaultTemplate from '../../Templates/DefaultTemplate';
import TemplateBackground from '../../../../Components/TemplateBackground';
import Init from '../../../Utils/Initializer';

const EditProfileSuccess = () => {
  Init('area-logada', 'editar-perfil:sucesso');
  const navigate = useNavigate();

  return (
    <>
      <DefaultTemplate>
        <Background>
          <TemplateBackground />
          <div className='login rounded-xl mt-[140px] lg:mt-20 lg:mb-0'>
            <div className="mb-5 w-full">
              <picture>
                <img
                  className="block mx-auto mt-2"
                  src={icon}
                />
              </picture>
            </div>
            <div>
              <h1 className="!mb-3">
                DADOS <strong>EDITADOS</strong>
                <br />
                COM <strong>SUCESSO</strong>
              </h1>
              <p className="leading-none text-center mt-4 mb-6" style={{ fontFamily: 'var(--bebas-bold)' }}>
                Obrigado por manter<br />
                suas informações atualizadas.
              </p>
            </div>
            <div>
              <Button
                onClick={() =>
                  navigate('/como-participar')
                }
                variant="button-red w-72 mt-1 mb-4 mx-auto"
                text="COMO PARTICIPAR"
              />

              <Button
                onClick={() => navigate('/editar-perfil')}
                variant="button-gray w-72 mx-auto"
                text="VOLTAR"
              />
            </div>
          </div>
        </Background>
      </DefaultTemplate>
    </>
  );
};

export default EditProfileSuccess;
