import Client, { Response } from './Client';

export async function GetRoles(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await Client.get('incentive/roles', data);

            resolve({
                success: true,
                code: response.status,
                data: response.data
            });
        } catch (error) {
            resolve({
                success: false,
                code: error.response.status,
                data: error.response.data || null
            });
        }
    });
}