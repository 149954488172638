import { React, useContext, useEffect, useState } from "react";
import { GetDistributed } from '../../../../../Backend/PanelManager';
import { LoadingContext } from "../../../../../Shared/LoadingContext";
//import { useNavigate } from 'react-router-dom';
import '../TablePoints/Table.scss';

const TableExtract = (props) => {
    // const [results, setResults] = useState([]);
    const { setLoading } = useContext(LoadingContext);
    // const navigate = useNavigate();

    // const fetchResults = async () => {
    //     try {
    //         const response = await GetResults();
    //         setResults(response.data.results);
    //         if (results) {
    //             setLoading(false);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         setLoading(false);
    //         navigate('/');
    //     }
    // };

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedMonthLabel, setSelectedMonthLabel] = useState('');
    const [tableData, setTableData] = useState([]);
    const [monthPoints, setMonthPoints] = useState(null);
    const [monthDistributed, setMonthDistributed] = useState(false);

    const handleMonthChange = (event) => {

        setMonthPoints(null);
        setMonthDistributed(false);
        setSelectedMonthLabel('');
        setTableData([]);

        const selectedPeriod = event.target.value;
        setSelectedMonth(selectedPeriod);

        if (selectedPeriod !== '') {
            const selectedPeriodLabel = event.target.options[event.target.selectedIndex].text;
            setSelectedMonthLabel(selectedPeriodLabel);

            const GetDistributedData = async (selectedPeriod) => {

                setLoading(true);

                const data = {};
                data['month'] = selectedPeriod;

                const response = await GetDistributed(data);

                setMonthPoints(response.data.points);
                setMonthDistributed(response.data.distributed);
                setTableData(response.data.users);

                setLoading(false);
            };

            GetDistributedData(selectedPeriod);
        } else {
            setMonthPoints(null);
            setMonthDistributed(false);
            setSelectedMonthLabel('');
            setTableData([]);
        }
    };

    return (
        <>
            <div className='bg-white rounded-[20px] lg:w-[919px] mx-auto w-[316px] shadow-md py-5 lg:mt-12 -mt-6'>
                <div className="flex flex-col items-center justify-center">
                    <div className="inputs-container lg:w-[446px] w-[286px]">
                        <label className="pl-8">
                            Selecione o mês:
                        </label>
                        <select
                            className="input-field first-option-placeholder rounded-xl md:h-10 xl:h-14"
                            id="selectMonth"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                        >
                            <option value="">Selecione</option>
                            {props?.monthsDistributed?.map((item) => (
                                <option key={item.period} value={item.period}>
                                    {item.month}
                                </option>
                            ))}
                        </select>
                    </div>


                    {tableData.length > 0 ?
                        <>

                            {monthDistributed === true &&
                                <>
                                    <div className="hidden lg:block">
                                        <table>
                                            <tr>
                                                <td colSpan={3} className='bg-[#CC0000] text-white text-[24px] w-[689px] uppercase'>PONTUAÇÃO {selectedMonthLabel}: {monthPoints}</td>
                                            </tr>
                                            {tableData.map((item) => (
                                                <tr key={item.user_name}>
                                                    <td>{item.user_name}</td>
                                                    <td>{item.user_role}</td>
                                                    <td>{item.points}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>

                                    {/* mobile firt table */}
                                    <div className="lg:hidden">
                                        <div className="flex flex-col justify-normal items-center mt-6">
                                            <div className="flex justify-center items-center w-[231px] h-[50px] bg-[#CC0000] rounded-t-[15px]">
                                                <h1 className='text-white text-[22px] uppercase'>PONTUAÇÃO {selectedMonthLabel}: {monthPoints}</h1>
                                            </div>
                                            <div className="table table-points">
                                                {tableData.map((item) => (
                                                    <>
                                                        <table
                                                            className="p-0 mb-2"
                                                            key={item.user_name}
                                                        >
                                                            <tr>
                                                                <td>{item.user_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="bg-[#C0C8C8]">{item.user_role}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>{item.points}</td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }


                        </> : ''}
                </div>

                {monthPoints > 0 && monthDistributed === false &&
                    <div className="flex flex-col justify-center items-center mx-auto lg:rounded-[13px] rounded-[15px] border-[#000000] border-[1px] lg:w-[404px] w-[286px] lg:h-[113px] h-[94px] lg:mt-7 lg:mb-4 mt-6 mb-4">
                        <h1 className="lg:text-[26px] text-[20px] text-[#CC0000] font-bold">PONTUAÇÃO PENDENTE</h1>
                        <h2 className="lg:text-[26px] text-[20px] text-[#000000] underline" style={{ fontFamily: 'var(--bebas-light)' }}>Distribua os pontos para o mês de {selectedMonthLabel}.</h2>
                    </div>
                }

                {monthPoints === 0 &&
                    <div className="flex flex-col justify-center items-center mx-auto lg:rounded-[13px] rounded-[15px] border-[#000000] border-[1px] lg:w-[404px] w-[286px] lg:h-[113px] h-[78px] lg:mt-7 lg:mb-4 mt-6 mb-6">
                        <h2 className="lg:text-[26px] text-[20px] text-[#000000] lg:leading-[30px] leading-[20px] text-center" style={{ fontFamily: 'var(--bebas-light)' }}>Sua concessionária não conquistou<br /> pontos no mês de {selectedMonthLabel}.</h2>
                    </div>
                }
            </div >
        </>
    )
}

export default TableExtract;