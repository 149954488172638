import './ButtonStyles.scss';

const Button = (props) => {
  return (
    <button
      {...props}
      className={`${props.variant} rounded-[28px]`}
    >
      {props.text}
    </button>
  );
};

export default Button;
