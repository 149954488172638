import Button from '../../../../Components/Button';
import recoverPasswordSuccessIcon from '../../../Media/Incentive/Icons/new-password.svg';

import '../Login/LoginStyles.scss';

import Init from "../../../Utils/Initializer";

const RecoverPasswordSuccess = ({ setViewLogin }) => {
  Init('area-aberta', 'nova-senha-solicitada-com-sucesso');
  const handleReturnToLogin = () => {
    setViewLogin('login');
  };
  return (
    <div className='login rounded-xl mt-7 lg:mt-0 mb-[100px] lg:mb-0'>
      <div>
        <picture>
          <img
            className='mx-auto block mb-5 mt-1'
            src={recoverPasswordSuccessIcon}
            alt="Senha Solicitada com sucesso"
          />
        </picture>
        <div>
          <h1>
            <strong>Nova senha</strong>{' '}
            solicitada<br />
            com{' '}
            <strong>sucesso!</strong>
          </h1>
        </div>
      </div>
      <div className='my-5 text-center leading-none'>
        <p>
          Caso seu CPF esteja cadastrado,<br />
          enviaremos um link por e-mail.
        </p>
        <p className='mt-4'>
          Confira sua caixa de entrada, spam<br />
          ou lixo eletrônico e siga as instruções.
        </p>
      </div>
      <Button
        type="button"
        variant="button-gray w-full mt-4 mb-1"
        text="VOLTAR AO LOGIN"
        onClick={handleReturnToLogin}
      />
    </div>
  );
};

export default RecoverPasswordSuccess;
